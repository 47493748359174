/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import getClientIp from '../../lib/getClientIp'
import { setAuthToken as setToken } from '../../lib/auth'
import api from '../../api/auth'
import { AccountStatus, ApiStatus, AuthState, AuthToken, VerifyCodeStatus } from '../../types'
import { AuthStartResponse, SendCodeThunk } from './AuthType'

export const AUTH_STATE_KEY = 'auth'

export interface AuthPartialState {
  [AUTH_STATE_KEY]: AuthState
}

const initialState: AuthState = {
  apiStatus: ApiStatus.idle,
  authToken: null,
  code: null,
  phoneNo: null,
  codeSent: null,
  error: null,
  firstName: null,
  lastName: null,
  isVerified: null,
  expiryTime: null,
  redirectUrl: null,
  signedIn: null,
  user: null,
  errorMessage: '',
  accountStatus: AccountStatus.Initial,
  expiredBlockingAccout: null,
}

const asyncReducers = {
  sendCode: createAsyncThunk<SendCodeThunk, { phoneNo: string; forSignUp?: boolean }>(
    'auth/start',
    async (params, thunkApi) => {
      try {
        const { phoneNo, forSignUp } = params

        const response = await api.post<AuthStartResponse>(`/auth/start`, {
          phoneNo,
          forSignUp,
        })

        return { ...response.data, accountStatus: AccountStatus.Verify }
      } catch (err) {
        const error = err as AxiosError
        if (error.response.status === 403) {
          return {
            accountStatus:
              err.response.data.error === 'Forbidden-IP'
                ? AccountStatus.BlockedIp
                : AccountStatus.TemporaryBlock,
            expiresIn: null,
            user: null,
          }
        }
        const customError = {
          name: 'error',
          message: err.response.data.message,
          data: error.response.data,
        }
        return thunkApi.rejectWithValue(customError)
      }
    }
  ),

  verifyCode: createAsyncThunk<
    { authToken: AuthToken; status: VerifyCodeStatus },
    { phoneNo: string; code: string }
  >('auth/verify', async (params, thunkApi) => {
    const { phoneNo, code } = params

    try {
      const response = await api.post<AuthToken>('/auth/verify', {
        phoneNo,
        code,
      })
      return {
        authToken: response.data,
        status: VerifyCodeStatus.Success,
      }
    } catch (error) {
      const axiosError = error as AxiosError
      if (axiosError.response.status === 403) {
        return { authToken: null, status: VerifyCodeStatus.Block }
      }
      if (axiosError.response.status === 401) {
        return { authToken: null, status: VerifyCodeStatus.Wrong }
      }

      return thunkApi.rejectWithValue(axiosError)
    }
  }),

  verifySignUpCode: createAsyncThunk<VerifyCodeStatus, { phoneNo: string; code: string }>(
    'auth/verifySignUpCode',
    async (params: { phoneNo: string; code: string }, thunkApi) => {
      try {
        const { phoneNo, code } = params
        const response = await api.post<boolean>('/auth/signup-verify', {
          phoneNo,
          code,
        })
        return response.data ? VerifyCodeStatus.Success : VerifyCodeStatus.Wrong
      } catch (error) {
        if (error.response.status === 403) {
          return VerifyCodeStatus.Block
        }
        if (error.response.status === 429) {
          return VerifyCodeStatus.TooManyRequest
        }
        if (error.response.status === 401) {
          return VerifyCodeStatus.Wrong
        }
        return thunkApi.rejectWithValue(error)
      }
    }
  ),

  signUpUser: createAsyncThunk(
    'auth/signUpUser',
    async (params: {
      firstName: string
      lastName: string
      phoneNo: string
      zipCode: string
      city: string
      streetAddress: string
      preferredLanguage: string
      phoneNoVerified: boolean
    }) => {
      const {
        firstName,
        lastName,
        phoneNo,
        preferredLanguage,
        zipCode,
        city,
        streetAddress,
        phoneNoVerified,
      } = params
      const response = await api.post('/auth/signup-user', {
        firstName,
        lastName,
        phoneNo,
        zipCode,
        city,
        streetAddress,
        preferredLanguage,
        phoneNoVerified,
      })
      return response.data
    }
  ),

  sendUpdateCode: createAsyncThunk(
    'auth/sendUpdateCode',
    async (params: { phoneNo?: string; email?: string; preferredLanguage: string }) => {
      const { phoneNo, email, preferredLanguage } = params
      const response = await api.post('/auth/send-code', {
        phoneNo,
        email,
        preferredLanguage,
      })
      return response.data
    }
  ),
  verifyUpdateCode: createAsyncThunk(
    'auth/verifyUpdateCode',
    async (params: { phoneNo?: string; email?: string; code: string }) => {
      const { phoneNo, email, code } = params
      const response = await api.post('/auth/verify-code', {
        phoneNo,
        email,
        code,
      })
      return response.data
    }
  ),

  refreshAuthToken: createAsyncThunk(
    'auth/refreshAuthToken',
    async (params: { accessToken: string; idToken: string }) => {
      const { accessToken, idToken } = params
      const ip = (await getClientIp()) || ''
      const response = await api.post('/auth/refresh-token', {
        accessToken,
        idToken,
        ip,
      })
      return response.data
    }
  ),

  verifyCodeForPartner: createAsyncThunk<
    { status: VerifyCodeStatus },
    { phoneNo: string; code: string }
  >('auth/partnerVerify', async (params, thunkApi) => {
    const { phoneNo, code } = params

    try {
      const response = await api.post<boolean>('/auth/partner/verify', {
        phoneNo,
        code,
      })
      return { status: response.data ? VerifyCodeStatus.Success : VerifyCodeStatus.Wrong }
    } catch (error) {
      const axiosError = error as AxiosError
      if (axiosError.response.status === 401) {
        return { status: VerifyCodeStatus.Wrong }
      }

      return thunkApi.rejectWithValue(axiosError)
    }
  }),

  verifyPartner: createAsyncThunk<AuthToken, { key: string }>(
    'auth/verifyPartner',
    async (params, thunkApi) => {
      const { key } = params

      try {
        const response = await api.post<AuthToken>('/auth/partner', {
          key,
        })
        return response.data
      } catch (error) {
        const axiosError = error as AxiosError
        return thunkApi.rejectWithValue(axiosError)
      }
    }
  ),
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialAuthState: () => {
      return initialState
    },
    setRedirectUrl: (authState: AuthState, action) => {
      return {
        ...authState,
        redirectUrl: action.payload,
      }
    },
    setAuthToken: (authState: AuthState, action) => {
      return {
        ...authState,
        authToken: action.payload,
      }
    },
    resetErrors: (authState: AuthState) => {
      return {
        ...authState,
        error: '',
      }
    },
    // resets all of auth but keeps authToken in
    resetAuth: (authState: AuthState) => {
      return {
        ...initialState,
        authToken: authState.authToken,
      }
    },
    resetSmsRequest: (authState: AuthState) => {
      return {
        ...authState,
        isVerified: VerifyCodeStatus.Initial,
        codeSent: null,
      }
    },
    setApiStatus: (authState: AuthState, action) => {
      return {
        ...authState,
        apiStatue: action.payload,
      }
    },
    resetCode: (customerState: AuthState) => {
      return {
        ...customerState,
        codeSent: false,
      }
    },
    resetVerified: (customerState: AuthState) => {
      return {
        ...customerState,
        isVerified: VerifyCodeStatus.Initial,
      }
    },
    setVerified: (customerState: AuthState, action: PayloadAction<VerifyCodeStatus>) => {
      return {
        ...customerState,
        isVerified: action.payload,
      }
    },
    resetAccountStatus: (authState: AuthState) => {
      return {
        ...authState,
        accountStatus: AccountStatus.Initial,
      }
    },
    'start/pending': (authState: AuthState) => {
      return { ...authState, apiStatus: ApiStatus.pending }
    },
    'start/fulfilled': (authState: AuthState, action: PayloadAction<SendCodeThunk>) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        codeSent: action.payload.result,
        user: action.payload.user,
        accountStatus: action.payload.accountStatus,
        expiryTime: action.payload.expiresIn,
        error: null,
        expiredBlockingAccout: null,
      }
    },
    'start/rejected': (
      authState: AuthState,
      action: PayloadAction<{ name: string; message?: string; data?: any }>
    ) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        codeSent: false,
        accountStatus: AccountStatus.Failed,
        errorMessage: action.payload?.message,
      }
    },
    'verify/pending': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.pending,
      }
    },
    'verify/fulfilled': (
      authState: AuthState,
      action: PayloadAction<{ authToken: AuthToken; status: VerifyCodeStatus }>
    ) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        isVerified: action.payload.status,
        authToken: action.payload.authToken,
        error: null,
      }
    },
    'verify/rejected': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        isVerified: VerifyCodeStatus.Wrong,
        authToken: null,
        error: 'Wrong authentication code',
      }
    },
    'verifySignUpCode/pending': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.pending,
      }
    },
    'verifySignUpCode/fulfilled': (
      authState: AuthState,
      action: PayloadAction<VerifyCodeStatus>
    ) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        isVerified: action.payload,
        error: action.payload === VerifyCodeStatus.Block && 'This account is block in 5 minute.',
      }
    },
    'verifySignUpCode/rejected': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        isVerified: VerifyCodeStatus.Wrong,
        authToken: null,
        error: 'Wrong authentication code',
      }
    },
    'signUpUser/pending': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.pending,
      }
    },
    'signUpUser/fulfilled': (authState: AuthState, action) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        signedIn: !!action.payload,
        authToken: action.payload,
        error: null,
      }
    },
    'signUpUser/rejected': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        authToken: null,
        error: 'Wrong authentication code',
      }
    },
    'sendUpdateCode/pending': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.pending,
      }
    },
    'sendUpdateCode/fulfilled': (authState: AuthState, action) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        codeSent: action.payload,
        expiryTime: action.payload.expiresIn,
        error: null,
      }
    },
    'sendUpdateCode/rejected': (authState: AuthState, action) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        codeSent: false,
        error: action.payload,
      }
    },
    'verifyUpdateCode/pending': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.pending,
      }
    },
    'verifyUpdateCode/fulfilled': (authState: AuthState, action) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        isVerified: action.payload,
        error: null,
      }
    },
    'verifyUpdateCode/rejected': (authState: AuthState, action) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        isVerified: VerifyCodeStatus.Wrong,
        error: action.payload,
      }
    },
    'checkAuthToken/pending': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.pending,
      }
    },
    'checkAuthToken/fulfilled': (authState: AuthState, action) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        authToken: action.payload,
      }
    },
    'checkAuthToken/rejected': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        authToken: null,
      }
    },
    'partnerSendCode/pending': (authState: AuthState) => {
      return { ...authState, apiStatus: ApiStatus.pending }
    },
    'partnerSendCode/fulfilled': (
      authState: AuthState,
      action: PayloadAction<{ codeSent: boolean }>
    ) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        codeSent: action.payload.codeSent,
        error: null,
      }
    },
    'partnerSendCode/rejected': (
      authState: AuthState,
      action: PayloadAction<{ name: string; message?: string; data?: any }>
    ) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        codeSent: false,
        errorMessage: action.payload?.message,
      }
    },
    'partnerVerify/pending': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.pending,
      }
    },
    'partnerVerify/fulfilled': (
      authState: AuthState,
      action: PayloadAction<{ authToken: AuthToken; status: VerifyCodeStatus }>
    ) => {
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        isVerified: action.payload.status,
        error: null,
      }
    },
    'partnerVerify/rejected': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        isVerified: VerifyCodeStatus.Wrong,
        error: 'Wrong authentication code',
      }
    },
    'verifyPartner/pending': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.pending,
      }
    },
    'verifyPartner/fulfilled': (authState: AuthState, action: PayloadAction<AuthToken>) => {
      setToken(action.payload)
      return {
        ...authState,
        apiStatus: ApiStatus.fulfilled,
        authToken: action.payload,
        error: null,
      }
    },
    'verifyPartner/rejected': (authState: AuthState) => {
      return {
        ...authState,
        apiStatus: ApiStatus.rejected,
        authToken: null,
        error: 'Wrong partner key',
      }
    },
  },
})

export const {
  sendCode,
  verifyCode,
  verifySignUpCode,
  refreshAuthToken,
  sendUpdateCode,
  verifyUpdateCode,
  signUpUser,
  verifyCodeForPartner,
  verifyPartner,
} = asyncReducers

export const {
  initialAuthState,
  setRedirectUrl,
  resetErrors,
  setApiStatus,
  setAuthToken,
  resetSmsRequest,
  resetCode,
  resetAuth,
  resetVerified,
  setVerified,
  resetAccountStatus,
} = authSlice.actions
// need to export actions or save actions as a constant?

export const selectAuthState = (state: AuthPartialState) => state[AUTH_STATE_KEY]

export default authSlice.reducer
