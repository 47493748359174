import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import {
  selectAuthLoading,
  selectAuthVerified,
  selectUser,
} from '../state/authentication.selectors'

import { DelayedLoader } from '../../../../components/DelayedLoader/DelayedLoader'
import { saveRedirectUrl } from '../../../../modules/layout/layout.reducer'
import { UserRole } from '../../../../types'

export interface AuthenticationGuardProps {
  redirectPath?: string
  roles?: UserRole[]
}
/**
 * Component to manage authentication redirection, will check if user is authorized and render its children
 * or redirect to the specified redirect path
 * @param params
 * - children: react components we want to render in case the user is authenticated
 * - redirectPath: path to redirect in case user is not authorized
 * - roles: that are allowed to render children
 *
 * @example
 * // Allow all roles
 * <BrowserRouter>
 *   <Route exact path="/">
 *     <AuthenticationGuard redirectPath="/login">
 *       <Home />
 *     </AuthenticationGuard>
 *   </Route>
 * </BrowserRouter>
 *
 * // Allow only admins
 * <BrowserRouter>
 *   <Route exact path="/">
 *     <AuthenticationGuard redirectPath="/login" roles={[UserRole.Admin]}>
 *       <Home />
 *     </AuthenticationGuard>
 *   </Route>
 * </BrowserRouter>
 *
 */
export function AuthenticationGuard({
  children,
  redirectPath = '/auth/login',
  roles = [],
}: PropsWithChildren<AuthenticationGuardProps>) {
  const dispatch = useDispatch()
  const location = useLocation()
  const loading = useSelector(selectAuthLoading)
  const user = useSelector(selectUser)
  const verified = useSelector(selectAuthVerified)

  const rolesDictionary = useMemo(
    () => roles.reduce((state, role) => ({ ...state, [role]: true }), {}),
    [roles]
  )

  const roleAllowed = !roles.length || user?.roles.some((role) => rolesDictionary[role])

  const authorized = user && roleAllowed

  useEffect(() => {
    if (!loading && verified && !authorized) {
      dispatch(saveRedirectUrl(location.pathname))
    }
  }, [dispatch, location, loading, verified, authorized])

  if (loading || !verified) {
    return <DelayedLoader delay={100} />
  }

  return verified && authorized ? <>{children}</> : <Redirect to={redirectPath} />
}
