import { AxiosInstance } from 'axios'
import { AuthenticationResponse } from '../interfaces/authentication-response.interface'
import { Credentials } from '../interfaces/credentials.interface'

import getClientIp from '../../../../lib/getClientIp'
import { AuthStartResponse } from '../../../../modules/auth/AuthType'
import { AuthToken, User } from '../../../../types'
import { RequestOpt } from '../interfaces/request-otp.interface'
import { SignUpUser } from '../interfaces/signup-user.interface'
import { UpdateProfilePictureParams } from '../interfaces/update-profile-picture-params.interface'
import { SignupResponse } from '../types/sign-up-response.type'
import { LocalStorageService } from './local-storage.service'

export const AUTHENTICATION_STORAGE_KEY = 'AUTHENTICATION'

export const LAST_REQUEST_LS_KEY = 'LAST_REQUEST'

export class AuthenticationService {
  constructor(
    private readonly storage: LocalStorageService,
    private readonly httpClient: AxiosInstance
  ) {}

  getToken(): AuthToken {
    return this.storage.getItem<AuthToken>(AUTHENTICATION_STORAGE_KEY)
  }

  saveAuthToken(token: AuthToken): void {
    this.storage.setItem(AUTHENTICATION_STORAGE_KEY, token)
  }

  async getUser(): Promise<User> {
    const { data } = await this.httpClient.get<User>(`/auth/me`)
    return data
  }

  async updateUser(id: string, payload: Partial<Omit<User, '_id'>>) {
    const { data } = await this.httpClient.put(`/users/${id}`, payload)
    return data
  }

  async login(credentials: Credentials): Promise<AuthenticationResponse> {
    const { data } = await this.httpClient.post<AuthenticationResponse>(`/auth/login`, credentials)
    return data
  }

  logout(): void {
    this.storage.delete(AUTHENTICATION_STORAGE_KEY)
  }

  getLastRequest(): string {
    return this.storage.getItem(LAST_REQUEST_LS_KEY) ?? ''
  }

  deleteLastRequest(): void {
    this.storage.delete(LAST_REQUEST_LS_KEY)
  }

  async requestOTP(payload: RequestOpt): Promise<AuthStartResponse> {
    const { data } = await this.httpClient.post<AuthStartResponse>('/auth/start', payload)
    return data
  }

  async signUp(user: SignUpUser): Promise<SignupResponse> {
    const { data } = await this.httpClient.post<SignupResponse>('/auth/signup-user', user)
    return data
  }

  async updateProfilePicture({ id, file }: UpdateProfilePictureParams): Promise<User> {
    const formData = new FormData()
    formData.append('photo', file)

    const { data } = await this.httpClient.put<User>(`/users/${id}/photo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    return data
  }

  async refreshToken(token: AuthToken): Promise<AuthToken> {
    // TODO IS THIS REALLY NECESSARY?
    const ip = await getClientIp()

    const { data } = await this.httpClient.post<AuthToken>('/auth/refresh-token', {
      // TODO IS THIS REALLY NECESSARY?
      accessToken: token.accessToken,
      idToken: token.idToken,
      ip: ip ?? '',
    })

    return data
  }

  isAuthExpired(timeout: number): boolean {
    const lsLastRequest = this.getLastRequest()

    const lastRequest = parseInt(lsLastRequest, 10)

    if (!lastRequest) {
      return false
    }

    const delta = Date.now() - lastRequest

    const expired = delta > timeout

    return expired
  }
}
