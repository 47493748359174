import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from '@rikaiportal/shared/modules/store'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import 'configurable-date-input-polyfill'
import '@fortawesome/fontawesome-free/css/all.css'
import './tailwind.output.css'
import './i18n'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/de'

const container = document.getElementById('root')
const root = createRoot(container);
root.render(<Provider store={store}>
  <App />
</Provider>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
