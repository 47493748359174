// TODO this function needs to be changed once a compelete migration happens on the backend
// eslint-disable-next-line
const mapOlderSurveyAnswers = (answers: any): void => {
  answers.forEach((answer) => {
    if (answer.additionalAnswer && !Array.isArray(answer.additionalAnswer)) {
      const oldAnswer = answer.additionalAnswer
      if (answer.questionKey === 'smoke') {
        // eslint-disable-next-line
        answer.additionalAnswer = [{ key: 'smoke-per-week', value: oldAnswer }] // this line needs enahncment
      }
      if (answer.questionKey === 'regular-medication') {
        // eslint-disable-next-line
        answer.additionalAnswer = [{ key: 'regular-medication-why', value: oldAnswer }] // this line needs enahncment
      }
    }
  })
}
export default mapOlderSurveyAnswers
