export enum DoctorPaths {
  DOCTOR_PANEL_DASHBOARD = '/doctor-panel/dashboard',
  DOCTOR_PANEL_ORDER_DETAIL = '/doctor-panel/orders/:orderId',
  DOCTOR_PANEL_RESULT_PREVIEW = '/doctor-panel/orders/:orderId/preview',
  DOCTOR_PANEL_RESULT_VIEW = '/doctor-panel/orders/:orderId/result',
  SURVEY_INDEX = '/doctor-panel/order/:orderId/surveys/:customerSurveyId',
  SURVEY_QUESTION = '/doctor-panel/surveys/:customerSurveyId/page/:page',
  SURVEY_QUESTION_SUMMARY = '/doctor-panel/surveys/:customerSurveyId/summary',
  SURVEY_FINISH = '/doctor-panel/surveys/:customerSurveyId/finish',
  RESEARCH_APPROVAL = '/doctor-panel/surveys/:customerSurveyId/research-approval',
}

export default DoctorPaths
