import { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import { UnaryFunction } from '../types/unary-function.type'

/**
 * Creates an interceptor that adds accept-language header to every request
 * @param getLanguage: function that returns language
 * @example
 *
 * const axiosInstance = axios.create({
 *   baseURL: 'http://example.com',
 * })
 *
 * function getLanguage(): string {
 *   return i18next.language
 * }
 *
 * const languageInterceptor = createLanguageInterceptor(getLanguage)
 *
 * axiosInstance.interceptors.request.use(languageInterceptor)
 *
 */
export function createLanguageInterceptor(
  getLanguage: () => string
): UnaryFunction<InternalAxiosRequestConfig, InternalAxiosRequestConfig> {
  return (request: InternalAxiosRequestConfig) => {
    const language = getLanguage()
    request.headers['Accept-Language'] = language

    return request
  }
}
