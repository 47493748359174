export enum AdminPaths {
  ADMIN_INDEX = '/admin',
  ADMIN_RUNTIME = '/admin/runtime',
  ADMIN_DATA = '/admin/data',
  ADMIN_CONTENT = '/admin/content',
  ADMIN_UPLOAD = '/admin/upload',
  ADMIN_DOWNLOAD = '/admin/download',
  ADMIN_VOUCHER = '/admin/voucher',
  ADMIN_PARTNER = '/admin/partner',
  ADMIN_PARTNER_BLOCK_DAYS = '/admin/blocked-days',
}

export default AdminPaths
