import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import api from '../../api/customer'
import mapOlderSurveyAnswers from '../../lib/mapOlderSurveyAnswer'
import { ApiStatus, SurveyState, SurveyStatus } from '../../types'

const initialState: SurveyState = {
  currentOrderOfSurvey: null,
  customerSurvey: null,
  surveyQuestionGroup: null,
  surveyQuestions: [],
  apiStatus: ApiStatus.idle,
  successFlag: false,
  checkIsQuestionCompleted: false,
  surveyPDF: null,
}

const asyncReducers = {
  getCustomerSurvey: createAsyncThunk(
    'surveys/getCustomerSurvey',
    async (params: { customerSurveyId: string }) => {
      const response = await api.get(`/customer-surveys/${params.customerSurveyId}`)
      return response.data
    }
  ),
  getCustomerSurveyAnswers: createAsyncThunk(
    'surveys/getCustomerSurveyAnswers',
    async (params: { customerSurveyId: string }) => {
      const response = await api.get(`/customer-surveys/${params.customerSurveyId}/answers`)
      return response.data
    }
  ),
  getSurveyQuestion: createAsyncThunk(
    'surveys/getSurveyQuestion',
    async (params: { surveyQuestionId: string }) => {
      const response = await api.get(`/survey-questions/${params.surveyQuestionId}`)
      return response.data
    }
  ),
  getSurveyQuestionGroup: createAsyncThunk(
    'surveys/getSurveyQuestionGroup',
    async (params: { surveyQuestionGroupId: string }) => {
      const response = await api.get(`/survey-question-groups/${params.surveyQuestionGroupId}`)
      return response.data
    }
  ),
  getSurveyQuestionGroupByProductGroup: createAsyncThunk(
    'surveys/getSurveyQuestionGroup',
    async (params: { surveyQuestionGroupId: string; productGroupId: string }) => {
      const response = await api.get(
        `/survey-question-groups/${params.surveyQuestionGroupId}/${params.productGroupId}`
      )
      return response.data
    }
  ),
  saveCustomerSurveyAnswers: createAsyncThunk(
    'surveys/saveCustomerSurveyAnswers',
    async (
      params: {
        customerSurveyId: string
        answers: Array<{
          questionId: string
          answerValue: string | number | Date | boolean
        }>
      },
      thunkApi
    ) => {
      try {
        const response = await api.put(
          `/customer-surveys/${params.customerSurveyId}/answers`,
          params.answers
        )

        return response.data
      } catch (error) {
        const e = error as AxiosError
        if (e.response.status === 400 && error.response.data.message === 'Already_Complete') {
          return thunkApi.rejectWithValue(true) // already complete
        }
        return thunkApi.rejectWithValue(false) // other error
      }
    }
  ),
  saveCustomerSurveyStatus: createAsyncThunk(
    'surveys/saveCustomerSurveyStatus',
    async (params: {
      customerSurveyId: string
      status: SurveyStatus
      orderNo: string
      byDoctor: boolean
    }) => {
      const response = await api.put(`/customer-surveys/${params.customerSurveyId}/status`, {
        status: params.status,
        orderNo: params.orderNo,
        byDoctor: params.byDoctor,
      })

      return response.data
    }
  ),
  downloadSurveyPDF: createAsyncThunk(
    'surveys/downloadSurveyPDF',
    async (params: { id: string; lang: string }) => {
      const response = await api.get(`/customer-surveys/survey-download/${params.id}`, {
        params: {
          lang: params.lang,
        },
      })
      return response.data
    }
  ),
}

const surveySlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    initialSurveyState: () => {
      return initialState
    },
    setSurveyQuestions: (surveyState: SurveyState, action) => {
      return { ...surveyState, surveyQuestions: action.payload }
    },
    setSaveSuccess: (surveyState: SurveyState, action) => {
      return { ...surveyState, successFlag: action.payload }
    },
    setCustomerSurvey: (surveyState: SurveyState, action) => {
      return { ...surveyState, customerSurvey: action.payload }
    },
    setCurrentOrderOfSurvey: (surveyState: SurveyState, action) => {
      return { ...surveyState, currentOrderOfSurvey: action.payload }
    },
    setSurveyPDF: (state: SurveyState, action) => {
      return { ...state, surveyPDF: action.payload, apiStatus: ApiStatus.idle }
    },
    'getCustomerSurvey/pending': (surveyState: SurveyState) => {
      return { ...surveyState, apiStatus: ApiStatus.pending }
    },
    'getCustomerSurvey/fulfilled': (surveyState: SurveyState, action) => {
      return {
        ...surveyState,
        apiStatus: ApiStatus.fulfilled,
        customerSurvey: action.payload,
      }
    },
    'downloadSurveyPDF/pending': (surveyState: SurveyState) => {
      return { ...surveyState, apiStatus: ApiStatus.pending }
    },
    'downloadSurveyPDF/fulfilled': (surveyState: SurveyState, action) => {
      return {
        ...surveyState,
        apiStatus: ApiStatus.fulfilled,
        surveyPDF: action.payload,
      }
    },
    'downloadSurveyPDF/rejected': (surveyState: SurveyState) => {
      return {
        ...surveyState,
        apiStatus: ApiStatus.rejected,
      }
    },
    'getCustomerSurveyAnswers/pending': (surveyState: SurveyState) => {
      return { ...surveyState, apiStatus: ApiStatus.pending }
    },
    'getCustomerSurveyAnswers/fulfilled': (surveyState: SurveyState, action) => {
      return {
        ...surveyState,
        apiStatus: ApiStatus.fulfilled,
        customerSurvey: { ...surveyState.customerSurvey, answers: action.payload },
      }
    },
    'getSurveyQuestion/pending': (surveyState: SurveyState) => {
      return { ...surveyState, apiStatus: ApiStatus.pending }
    },
    'getSurveyQuestion/fulfilled': (surveyState: SurveyState, action) => {
      return {
        ...surveyState,
        apiStatus: ApiStatus.fulfilled,
        surveyQuestions: [action.payload],
        surveyQuestionGroup: null,
      }
    },
    'getSurveyQuestionGroup/pending': (surveyState: SurveyState) => {
      return { ...surveyState, apiStatus: ApiStatus.pending }
    },
    'getSurveyQuestionGroup/fulfilled': (surveyState: SurveyState, action) => {
      return {
        ...surveyState,
        apiStatus: ApiStatus.fulfilled,
        surveyQuestionGroup: action.payload,
        surveyQuestions: action.payload.questions, // surveyState.surveyQuestions.concat(action.payload.questions),
      }
    },
    'getSurveyQuestionGroupByProductGroup/pending': (surveyState: SurveyState) => {
      return { ...surveyState, apiStatus: ApiStatus.pending }
    },
    'getSurveyQuestionGroupByProductGroup/fulfilled': (surveyState: SurveyState, action) => {
      return {
        ...surveyState,
        apiStatus: ApiStatus.fulfilled,
        surveyQuestionGroup: action.payload,
        surveyQuestions: action.payload.questions, // surveyState.surveyQuestions.concat(action.payload.questions),
      }
    },
    'saveCustomerSurveyAnswers/pending': (surveyState: SurveyState) => {
      return { ...surveyState, apiStatus: ApiStatus.pending, successFlag: false }
    },
    'saveCustomerSurveyAnswers/fulfilled': (surveyState: SurveyState, action) => {
      mapOlderSurveyAnswers(action.payload.answers)
      return {
        ...surveyState,
        apiStatus: ApiStatus.fulfilled,
        customerSurvey: action.payload,
        successFlag: true,
      }
    },
    'saveCustomerSurveyAnswers/rejected': (surveyState: SurveyState, action) => {
      if (action.payload) {
        return {
          ...surveyState,
          apiStatus: ApiStatus.rejected,
          successFlag: false,
          checkIsQuestionCompleted: true,
        }
      }
      return { ...surveyState, apiStatus: ApiStatus.rejected, successFlag: false }
    },
    'saveCustomerSurveyStatus/pending': (surveyState: SurveyState) => {
      return { ...surveyState, apiStatus: ApiStatus.pending }
    },
    'saveCustomerSurveyStatus/fulfilled': (surveyState: SurveyState, action) => {
      return {
        ...surveyState,
        apiStatus: ApiStatus.fulfilled,
        customerSurvey: action.payload,
      }
    },
  },
})

export const {
  getCustomerSurvey,
  getSurveyQuestion,
  getSurveyQuestionGroup,
  getSurveyQuestionGroupByProductGroup,
  saveCustomerSurveyAnswers,
  getCustomerSurveyAnswers,
  saveCustomerSurveyStatus,
  downloadSurveyPDF,
} = asyncReducers

export const {
  initialSurveyState,
  setSurveyQuestions,
  setSaveSuccess,
  setCustomerSurvey,
  setCurrentOrderOfSurvey,
  setSurveyPDF,
} = surveySlice.actions

export default surveySlice.reducer
