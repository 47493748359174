import axios, { AxiosError } from 'axios'
import {
  authenticationClient,
  selectAuthToken,
  selectTokenRefreshing,
} from '../authentication-client/src/lib'
import { AppStore } from '../modules/store'
import { UnaryFunction } from '../types/unary-function.type'
import { getTokenAfterRefresh } from '../lib/get-token-after-refresh'

export interface CreateUnauthorizedInterceptorParams {
  onUnauthorized: (url: string) => void
  whitelistedUrls?: string[]
  store: AppStore
}

export function createUnauthorizedInterceptor({
  onUnauthorized,
  store,
  whitelistedUrls = [],
}: CreateUnauthorizedInterceptorParams): UnaryFunction<AxiosError, Promise<void>> {
  return async (error: AxiosError) => {
    const { status } = error.response

    if (status !== 401) {
      throw error
    }

    const token = selectAuthToken(store.getState())

    if (token && Date.now() >= Date.parse(token.expiresIn)) {
      const refreshing = selectTokenRefreshing(store.getState())

      if (!refreshing) {
        store.dispatch(authenticationClient.refreshToken())

        const newToken = await getTokenAfterRefresh(store)

        return axios.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${newToken?.idToken}`,
          },
        })
      }

      const newToken = await getTokenAfterRefresh(store)

      return axios.request({
        ...error.config,
        headers: {
          ...error.config.headers,
          Authorization: `Bearer ${newToken?.idToken}`,
        },
      })
    }

    const { url } = error.config

    const isWhitelisted = whitelistedUrls.some((wlUrl) => wlUrl === url)

    if (!isWhitelisted) {
      onUnauthorized(url)
    }

    throw error
  }
}
