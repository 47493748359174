import { DelayedLoader } from '@rikaiportal/shared/components/DelayedLoader/DelayedLoader'
import ScrollToTop from '@rikaiportal/shared/components/ScrollToTop'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AdminRoutes from './admin/AdminRoutes'
import AuthRoutes from './auth/AuthRoutes'
import ErrorBoundary from './components/ErrorBoundary'
import CrmRoutes from './crm/CrmRoutes'
import DoctorRoutes from './doctor/DoctorRoutes'
import HomesamplingRoutes from './homesampling'
import { ROUTE_CONFIG } from './route.config'
import { setupInterceptors } from '@rikaiportal/shared/api/interceptors.config'
import {
  AUTHENTICATION_TIMEOUT,
  AuthenticationGuard,
  authenticationClient,
} from '@rikaiportal/shared/authentication-client/src/lib'
import store from '@rikaiportal/shared/modules/store'

const Home = lazy(() => delayedImport('home', ROUTE_CONFIG))

setupInterceptors(store)

store.dispatch(authenticationClient.verifyAuth(AUTHENTICATION_TIMEOUT))

const App: React.FunctionComponent = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Suspense fallback={<DelayedLoader delay={ROUTE_CONFIG.fallbackTime} />}>
            <AuthRoutes />
            <AdminRoutes />
            <CrmRoutes />
            <DoctorRoutes />
            <HomesamplingRoutes />

            <Route exact path="/">
              <AuthenticationGuard>
                <Home />
              </AuthenticationGuard>
            </Route>
          </Suspense>
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App
