import api from '../api/auth'
import getClientIp from './getClientIp'
import { AuthToken } from '../types'

export const checkAuthToken = (): boolean => {
  try {
    return !!JSON.parse(sessionStorage.getItem('authToken'))
  } catch (e) {
    return false
  }
}

export const getAuthToken = (): AuthToken => {
  try {
    const authToken = JSON.parse(sessionStorage.getItem('authToken'))

    if (!authToken.accessToken || !authToken.idToken) throw new Error('AuthToken is not valid')

    return authToken
  } catch (e) {
    return {
      accessToken: null,
      idToken: null,
      expiresIn: null,
    }
  }
}

export const setAuthToken = (authToken: AuthToken): boolean => {
  try {
    if (!authToken.accessToken || !authToken.idToken) throw new Error('AuthToken is not valid')
    sessionStorage.setItem('authToken', JSON.stringify(authToken))

    return true
  } catch (e) {
    return false
  }
}

export const refreshAuthToken = async (authToken: AuthToken): Promise<AuthToken> => {
  try {
    if (!authToken.accessToken || !authToken.idToken) throw new Error('AuthToken is not valid')
    const ip = (await getClientIp()) || ''

    const response = await api.post('/auth/refresh-token', {
      accessToken: authToken.accessToken,
      idToken: authToken.idToken,
      ip,
    })
    const { data: newAuthToken } = response

    setAuthToken(newAuthToken)

    return newAuthToken
  } catch (e) {
    return {
      accessToken: null,
      idToken: null,
      expiresIn: null,
    }
  }
}

export const removeAuthToken = async (callback: () => void): Promise<boolean> => {
  try {
    sessionStorage.removeItem('authToken')

    await callback()

    return true
  } catch (e) {
    return false
  }
}
