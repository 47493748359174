/**
 * Waits for a specified period of time then resolves
 * @param time to wait
 * @example
 *
 * async function main() {
 *  console.log('Hello')
 *  await sleep(1000)
 *  console.log('How are you')
 * }
 *
 * main()
 *
 */
export function sleep(time: number): Promise<void> {
  return new Promise((resolve) => {
    if (time <= 0) {
      return resolve()
    }

    const timerId = setTimeout(() => {
      resolve()
      clearTimeout(timerId)
    }, time)
  })
}
