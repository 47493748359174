import axios from 'axios'

const getClientIp = async (): Promise<string> => {
  let ip

  try {
    const response = await axios.get('https://api4.my-ip.io/ip.json')

    ip = response?.data?.ip
  } catch (e) {
    ip = null
  }

  return ip
}

export default getClientIp
