import { createSelector } from '@reduxjs/toolkit'
import { AUTHENTICATION_STATE_KEY, AuthenticationPartialState } from './authentication.state'

export const selectAuthenticationState = (state: AuthenticationPartialState) =>
  state[AUTHENTICATION_STATE_KEY]

export const selectAuthToken = createSelector(selectAuthenticationState, ({ token }) => token)

export const selectTokenExpiration = createSelector(
  selectAuthenticationState,
  ({ token }) => token?.expiresIn ?? ''
)

export const selectAuthTokenId = createSelector(selectAuthToken, (token) => token?.idToken ?? '')

export const selectAuthLoading = createSelector(selectAuthenticationState, ({ loading }) => loading)

export const selectAuthVerified = createSelector(
  selectAuthenticationState,
  ({ verified }) => verified
)

export const selectUser = createSelector(selectAuthenticationState, ({ user }) => user)

export const selectAuthError = createSelector(selectAuthenticationState, ({ error }) => error)

export const selectTokenRefreshing = createSelector(
  selectAuthenticationState,
  ({ refreshing }) => refreshing
)
