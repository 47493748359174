export interface GetItemOptions {
  json?: boolean
}

export class LocalStorageService {
  getItem<T>(key: string, options: GetItemOptions = { json: true }): T | null {
    const data = localStorage.getItem(key)
    const result: T | null = options.json ? JSON.parse(data) : data
    return result
  }

  setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value))
    return
  }

  delete(key: string): void {
    localStorage.removeItem(key)
  }
}
