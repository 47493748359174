import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import HomesamplingPath from '@rikaiportal/shared/modules/homesampling/HomesamplingePath'
import { UserRole } from '@rikaiportal/shared/types'
import React, { FunctionComponent, lazy } from 'react'
import { Switch } from 'react-router'
import { ROUTE_CONFIG } from '../route.config'

const HomesamplingOrders = lazy(() =>
  delayedImport('homesampling/HomesamplingOrders', ROUTE_CONFIG)
)

const HomesamplingRoutes: FunctionComponent = () => {
  return (
    <>
      <Switch>
        <ProtectedRoute
          exact
          path={HomesamplingPath.ORDER_INDEX}
          component={HomesamplingOrders}
          allow={[UserRole.HOSA_CRM]}
        />
      </Switch>
    </>
  )
}

export default HomesamplingRoutes
