export type BaseEntity = {
  _id?: string
  createdAt?: Date
  updatedAt?: Date
  validFrom?: Date
  validTo?: Date
  ver?: number
}

export enum ApiStatus {
  idle,
  pending,
  fulfilled,
  rejected,
}

export type Translation = {
  en: string
  de: string
  fr: string
  it: string
}

export type MapPosition = {
  lat: number
  lng: number
}

export enum LoadingStatusItem {
  More = 'More',
  End = 'End',
}

export type DateRange = {
  from: Date
  to: Date
}

export type ApiStateData<T> = {
  status: ApiStatus
  data?: T
  error?: number
  errorMessage?: string
}

export type CmsDocuments = {
  resultCategories: boolean
  doctorRecommendations: boolean
  badges: boolean
  footerLinks: boolean
  storeMaintenance: boolean
  pages: boolean
  criticalLimit: boolean
}

export enum UserRole {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  DOCTOR = 'doctor',
  CRM_ADMIN = 'crm_admin',
  HOSA_CRM = 'HOSA_CRM',
  DATA_EXPORT = 'data_export',
}

export enum Application {
  RIKAI = 'rikai',
  HOMESAMPLING = 'homesampling',
}

export type Code = {
  _id: string
  type: string
  key: string
  value: Translation
}

export type User = {
  _id: string
  uuid: string
  doctorTitle?: string
  roles: UserRole[]
  firstName: string
  lastName: string
  phoneNo: string
  sex?: string
  birthDate?: Date
  streetAddress?: string
  city?: string
  zipCode: string
  email?: string
  phoneNoVerified?: boolean
  emailVerified?: boolean
  photoAvatarUrl?: string
  preferredLanguage?: string
  mostRecentOrderStatus?: OrderStatus | string
  lastOpenedAt?: Date
  allOrderNumbers?: string[]
  locked?: boolean
  deleted?: boolean
  bloodGroup?: BloodGroupResult
  seenResearchApproval?: boolean
  researchApproval?: boolean
  passport?: string
  country?: string
}

export type BloodDrawMethod = {
  key: string
  default: boolean
  name: Translation
}

export type Product = {
  _id: string
  key: string
  name: Translation
  description: Translation
  imageURL: string
  price: number
  active: boolean
  productGroupId: string
  productGroup?: ProductGroup
  addons?: AddOnProduct[]
  totalPartnerBranches: number
  id?: string
  steps?: Translation[]
  customerId?: string
  medicalName?: Translation
  values?: string[] | Translation[]
  months?: number
  orderId?: string
  order?: Order
  bloodDrawMethod?: BloodDrawMethod[]
  details?: Translation
}

export type ProductGroup = {
  _id: string
  name: Translation
  key: string
  description: Translation
  daysResult: number
  startingPrice: number
  currency: string
  active: boolean
  products: Product[]
}

export type AddOnProduct = {
  _id: string
  name: Translation
  description: Translation
  bloodMeasureIds: string[]
  price: number
  oneTimeFlag: boolean
  active: boolean
  details?: Translation
  key?: string
  tags?: string[]
}

export type AssignedProductId = {
  application: Application
  channel?: Channel
  id: string
}

export enum ProductGroupKey {
  PILOT = 'pilot',
  SMALL_PROFILES = 'small-profiles',
}

export enum ProductKey {
  PHARMACY_PILOT = 'pharmacy-pilot',
}

export type PartnerBranch = {
  _id: string
  name: string
  key: string
  address: string
  country: string
  location: Location
  openingHours: OpeningHours
  operatingHours: OpeningHours
  active: boolean
  maxAppointmentsPerTimeSlot: number
  productIds: AssignedProductId[]
  products?: Product[]
  productGroups?: ProductGroup[]
  temporaryClose?: DateRange[]
  timeSlotDuration: number
  streetName?: string
  streetNumber?: string
  zipCode?: string
  city?: string
  addressDescription?: string
  orders?: Order[]
  permanentlyClose?: DateRange
  additionalEmailAddress?: string
  allowDataSharing?: boolean
  allowAutoDataSharing?: boolean
  disclaimer?: string
  logo?: string
  phoneNo?: string
  application?: Application
}

export enum Disclaimer {
  NO_DISCLAIMER = '',
  GALENICARE = 'Galenicare',
}

export type OpeningHours = {
  weekdayText: string[]
  periods: Array<Record<string, unknown>>
}

export type Location = {
  type: string
  coordinates: number[]
}

export type BlockedDays = {
  _id: string
  scheduleFrom: Date
  scheduleTo: Date
  reason: string
  orders?: Order[]
}

export type RangeValue = {
  min?: number
  max?: number
}

export enum OrderFlowNo {
  WITH_ORDER = 1,
  AFTER_ORDER = 2,
}

export type RescheduleFee = {
  _id: string
  key: string
  description: Translation
  price: number
}

export enum RescheduleFeeKeys {
  LATE_FEE = 'late-fee',
  EXTRA_LATE_FEE = 'extra-late-fee',
  AFTER_APPOINTMENT_FEE = 'after-appointment-fee',
}

export type AppointmentState = {
  allRescheduleFees: ApiStateData<RescheduleFee[]>
  currentRescheduleFee: ApiStateData<RescheduleFee>
  appointments: ApiStateData<Appointment[]>
  appointmentCSV: ApiStateData<string>
}

export type OrderTransaction = {
  rikaiTransactionNo: string
  addons?: AddOnProduct[]
  rescheduleFee?: RescheduleFee
  appointment?: {
    scheduleFrom: Date
    scheduleTo: Date
  }
  timeStamp: Date
  flowNo: OrderFlowNo
  price: number
  voucher?: {
    code: string
    discount: number
  }
  payment?: {
    transactionId?: string
    status?: string
    settledAt?: Date
    paymentMethod?: string
    history?: Array<Record<string, unknown>>
    currency?: string
    card?: {
      masked?: string
      info?: {
        brand?: string
      }
    }
  }
  cancellationReasons?: string
}

export type PreviousReportLink = {
  orderId: string
  orderNo: string
  testResultId: string
  testDate: Date
}

export enum Channel {
  CUSTOMER = 'direct',
  PARTNER_SALES = 'partner',
}

export type Address = {
  streetAddress?: string
  city?: string
  zipCode?: string
}

export type Order = {
  _id: string
  customerId: string
  productId: string
  orderNo: string
  shortCode: string
  productPrice: number
  status: OrderStatus
  transactions?: Array<OrderTransaction>
  preparationSurveyId: string
  allowShareData?: boolean
  dateAllowShareData?: Date
  customer?: User
  product?: Product
  productGroup?: ProductGroup
  partnerBranch?: PartnerBranch
  appointment?: Appointment
  preparationSurvey?: CustomerSurvey
  createdAt?: Date
  testResult?: TestResult
  lastUpdatedTransactionNo?: string
  addOnsOrder?: Partial<AddOnProduct[]>
  addOns?: AddOnProduct[]
  supportUUID?: string
  support?: Support
  sampleTakingTime?: Date
  supportRequestedCount?: number
  registrationDate?: Date
  isSpecialDataSharingOrder?: boolean
  channel?: Channel
  seenTermsAndConditions?: boolean
  isFollowUp?: boolean
  deliveryAddress?: Address
  invoiceAddress?: Address
  mainOrderId?: string
  mainResultId?: string
  deliveryDate?: Date
  months?: number
  bloodDrawMethod?: string
}

export enum OrderStatus {
  PAID,
  CONFIRMED,
  ANALYZING,
  RESULT_RECEIEVED,
  COMPLETED,
  CANCELLED,
  READY_FOR_APPROVAL,
}

export enum AppointmentStatus {
  PENDING,
  CONFIRMED,
  CANCELLED,
  RESCHEDULED,
}

export enum BloodDrawMethodKey {
  VENOUS = 'venous',
  CAPILLARY = 'capillary',
}

export type Appointment = {
  _id: string
  orderId: string
  partnerBranchId: string
  scheduleFrom: Date
  scheduleTo: Date
  status?: number
  order?: Order
  product?: Product
  productGroup?: ProductGroup
  customer?: User
  partnerBranch?: PartnerBranch
  customerSurvey?: CustomerSurvey
  onlineAppointmentLink?: string
  application: Application
  createdAt?: Date
}

export type TimeSlot = {
  from: Date
  to: Date
  appointments: Appointment[]
}

export type MultiDaysTimeSlot = {
  date: Date
  timeSlots: TimeSlot[]
}

export enum MeasureLevel {
  OUTSTANDING = 'outstanding',
  GOOD = 'good',
  BELOW_AVERAGE = 'belowAverage',
  BAD = 'bad',
  UNJUDGEABLE = 'unjudgeable',
}

export type MeasureValueRange = {
  sex?: string
  age?: RangeValue
  values: {
    good: RangeValue
    normal?: RangeValue
    caution?: RangeValue
  }
}

export type BloodMeasure = {
  _id: string
  key: string
  name: Translation
  description: Translation
  unit: string
  ranges: MeasureValueRange[]
  productGroupIds: string[]
  categoryId: string
}

export type ResultCategory = {
  _id: string
  key: string
  name: Translation
  summaryText: Array<{
    filters: Array<{
      healthMeasureId: string
      level: MeasureLevel
    }>
    title: string
    notes: string
    text: Translation
  }>
  selfComparisonDescriptions: Translation[]
  healthMeasureIds: string[]
  productGroupIds: string[]
  productIds: string[]
}

export type HealthMeasure = {
  _id: string
  key: string
  name: Translation
  icon: string
  description: Translation
  bloodMeasureIds: string[]
  surveyQuestionIds: string[]
}

export type ResultRecommendation = {
  _id: string
  key: string
  name: Translation
  description: Translation
  icon: string
  bgColor: string
  titleColor: string
  descriptionColor: string
  active: boolean
  link: {
    text: Translation
    url: Translation
  }
}

export enum InternalNoteActivityType {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
}

export type InternalNote = {
  _id?: string
  userId: string
  note: string
  timestamp: Date
  user?: User
}

export enum SurveyQuestionKey {
  HEIGHT = 'height',
  WEIGHT = 'weight',
  SEX = 'sex',
  DAY_OF_BIRTH = 'day-of-birth',
  DIET = 'diet',
  BLOOD_DONOR = 'blood-donor',
  BLOOD_DONOR_FREQUENCY = 'blood-donor-frequency',
  BLOOD_DONOR_RECENTLY = 'blood-donor-recently',
  ALCOHOL_CONSUMPTION_FREQUENCY = 'alcohol-consumption-frequency',
  ALCOHOL_CONSUMPTION_DRINKS = 'alcohol-consumption-drinks',
  ALCOHOL_CONSUMPTION_OCCASION = 'alcohol-consumption-occasion',
  TEST_RESULT_LANGUAGE = 'test-result-language',
  REASON = 'reason',
  REGULAR_MEDICATION = 'regular-medication',
  SMOKE_WHEN = 'smoke-when',
  SMOKE = 'smoke-2',
  SMOKE_HOW_LONG = 'smoke-how-long',
  SMOKE_SMOKE_HOW_MANY = 'smoke-how-many',
}

export type TestResult = {
  _id: string
  orderId: string
  customerId: string
  testDate: Date
  resultCategories: Array<{
    resultCategoryId: string
    level: MeasureLevel
    resultCategorySummary: Translation
    selfComparisonDescriptions: Translation
  }>
  releaseDate?: Date
  healthMeasuresResult: Array<{
    healthMeasureId: string
    level: MeasureLevel
  }>
  bloodMeasuresResult: BloodMeasuresResult[]
  categories?: ResultCategory[]
  healthMeasures?: HealthMeasure[]
  bloodMeasures?: BloodMeasure[]
  bloodMeasureCategories?: Code[]
  resultCategorySummary: Translation
  selfComparisonDescriptions: Translation
  recommendations?: ResultRecommendation[]
  followUpRecommendations?: FollowUpRecommendation[]
  createdAt: Date
  order?: Order
  patient?: User
  verifiedByDoctorText?: string
  hl7OrderId?: string
  pdfPath?: string
  pdfData?: string
  testResultLanguage: string
  internalNotes?: InternalNote[]
  bloodGroup?: BloodGroupResult
  releasedBy?: string
  testResultSmsId?: string
  customerSurvey?: CustomerSurvey
  createdBy?: string
}

export enum CriticalLimitType {
  UPPER = 'Upper',
  LOWER = 'Lower',
}

export type CriticalLimit = {
  name: Translation
  description: Translation
  bloodMeasureName: string
  value: number | string
  limitType: CriticalLimitType
}

export type BloodMeasuresResult = {
  bloodMeasureId: string
  level: MeasureLevel
  value: number
  valueStr: string
  unit: string
  range: { min: number; max: number }
  referenceStr: string
  criticalLimit?: CriticalLimit
  releaseDate?: Date
  testDate?: Date
  bloodMeasureKey?: string
}

export enum SurveyStatus {
  PENDING,
  IN_PROGRESS,
  COMPLETED,
}

export enum SurveyAnswerType {
  DATE = 'date',
  MONTH = 'month',
  SINGLE_VALUE = 'single_value',
  MULTIPLE_VALUES = 'multiple_values',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  YEAR = 'year',
}

export type SurveyAnswerValue = string | number | Date | boolean
export type SurveyAdditionalAnswerValue = { key: string; value: SurveyAnswerValue }[]

export type SurveyDefinition = {
  key: string
  name: Translation
  description: Translation
  pages: Array<{
    question?: string
    questionGroup?: string
  }>
}

export type SurveyQuestionCategory = {
  _id: string
  key: string
  name: Translation
}

export type SurveyQuestion = {
  horizontal?: boolean
  _id: string
  key: string
  active?: boolean
  questionCategory: SurveyQuestionCategory
  title?: Translation
  detail?: Translation
  question: Translation
  answerType: SurveyAnswerType
  answerOptions: Array<{
    text: Translation
    value: SurveyAnswerValue
  }>
  additionalAnswer?: Array<{
    answerType?: SurveyAnswerType
    displayOn: SurveyAnswerValue
    answerPlaceHolder?: Translation
    answerUnit?: Translation
    minValue?: number
    maxValue?: number
    question?: string
    title?: string
    key?: string
  }>

  productGroupIds: string
  answerDefaultValue?: SurveyAnswerValue
  answerPlaceHolder?: Translation
  answerUnit?: string
  minValue?: number
  maxValue?: number
  additionalInfo?: Translation
  reasonForQuestion?: Translation
  rules?: Record<string, Record<string, unknown>>
  requiresPrevious?: boolean
  followUpQuestionKeys?: string[]
}

export type SurveyQuestionGroup = {
  key: string
  name: Translation
  questionIds: string[]
  questions?: SurveyQuestion[]
}

export enum BloodGroupValue {
  NEGATIVE = '-',
  POSITIVE = '+',
}

export type BloodGroupResult = {
  group: string
  rh: BloodGroupValue
  kellAntigen: BloodGroupValue
  rhp: string
}

export type CustomerSurvey = {
  _id: string
  customerId: string
  surveyDefinitionId: string
  answers: Array<{
    questionId: string
    answerValue: SurveyAnswerValue
    question?: SurveyQuestion
    questionKey?: string
    questionGroup?: string
    additionalAnswer?: SurveyAdditionalAnswerValue
  }>
  status: SurveyStatus
  customer?: User
  surveyDefinition?: SurveyDefinition
  preparationSurveyForOrder?: Order
  questions?: SurveyQuestion[]
  product: Product
  productGroup: ProductGroup
  createdAt?: Date
}

export type RuntimeSetting = {
  name: string
  value: string | boolean | number | Array<{ name: string; value: boolean }>
  default?: boolean
}

export type ProductState = {
  productGroups: ProductGroup[]
  selectedProductGroup: ProductGroup
  selectedProduct: Product
  selectedProductGroupAddOns: AddOnProduct[]
  partnerBranchSearchText: string
  partnerBranchSearchLocation: MapPosition
  productPartnerBranches: PartnerBranch[]
  partnerBranch: PartnerBranch
  productGroupAddOns: AddOnProduct[]
  selectedPartnerBranch: PartnerBranch
  partnerBranchTimeSlots: TimeSlot[]
  partnerBranchTimeSlotsListInRange: GroupOfTimeSlots
  partnerBranchMultiDaysTimeSlots: MultiDaysTimeSlot[]
  selectedDate: Date
  selectedTimeSlot: TimeSlot
  productOrder: Order
  apiStatus: ApiStatus
  error: string
  customTimeSlot?: CustomTimeSlot
}

export type CustomTimeSlot = {
  from: string
  to: string
}

export type UserState = {
  currentUser: User
  apiStatus: ApiStatus
  error: string
  allUsers: User[]
  user: User
  isUpdated: boolean
}

export type FooterLink = {
  text?: Translation
  url: string
  openInNewWindow: boolean
  image?: string
  imageHeight?: string | number
  imageWidth?: string | number
}

export type FooterLinkCategory = {
  _id: string
  key: string
  type: string
  title?: Translation
  links: FooterLink[]
  active?: boolean
  updatedAt?: Date
}
export type PageWithTitleAndListItems = {
  key: string
  type: string
  title?: Translation
  listItems: Array<{
    text: Translation
  }>
  active?: boolean
  updatedAt?: Date
  supportedBloodDrawMethods: string[]
}

export type StoreMaintenance = {
  _id: string
  key: string
  type: string
  image?: {
    url?: string
    alt?: string
  }
  title?: Translation
  description?: Translation
  active?: boolean
  updatedAt?: Date
}

export type CmsState = {
  footerLinkCategories?: FooterLinkCategory[]
  pages?: PageWithTitleAndListItems[]
  storeMaintenance: StoreMaintenance
  apiStatus: ApiStatus
  error: string
}

export type SurveyState = {
  customerSurvey: CustomerSurvey
  surveyQuestionGroup: SurveyQuestionGroup
  surveyQuestions: SurveyQuestion[]
  apiStatus: ApiStatus
  successFlag: boolean
  currentOrderOfSurvey: string
  checkIsQuestionCompleted: boolean
  surveyPDF: string
}

export type OrderCsvData = {
  uniqueId: string
  productKey: string
  productId: string
  productName: string
  registrationDate: string
  status: string
}

export type OrderState = {
  currentOrder: Order
  orders: ApiStateData<Order[]>
  customerOrders: Order[]
  lazyLoadOrderStatus: ApiStateData<LoadingStatusItem>
  apiStatus: ApiStatus
  invoicePdf: ApiStateData<string>
  error: string
  orderRows?: ApiStateData<number>
  ordersCsv?: ApiStateData<OrderCsvData[]>
  deliveryAddress?: Address
  invoiceAddress?: Address
}

export type ResultState = {
  customerTestResults: TestResult[]
  customerTestResultsByOrder: TestResult
  resultCategories: ResultCategory[]
  healthMeasures: HealthMeasure[]
  bloodMeasures: BloodMeasure[]
  apiStatus: ApiStatus
  matchedOrders: Order[]
  historicalTestResultsByCustomer: ApiStateData<TestResult[]>
  distinctPreviousBloodMeasures: BloodMeasuresResult[]
  previousBloodMeasures: BloodMeasuresResult[]
  resultRecommendations: ResultRecommendation[]
  followUpRecommendations?: FollowUpRecommendation[]
  healthMeasuresResult: Array<{
    healthMeasureId: string
    level: MeasureLevel
  }>
  resultCategoryResult: Array<{
    resultCategoryId: string
    level: MeasureLevel
    resultCategorySummary?: Translation
  }>
  error?: string
  resultSaved?: boolean
  quickUploadSuccess?: boolean
  pdfUploadSuccess?: TestResult[]
  pdfUploadError?: string[]
  pdfUploadExists?: string[]
  pdfData?: string
  pdfEncoded?: boolean
  resultPdf: string
  previousReportLink?: PreviousReportLink
  tempResult?: string
  filename: string
  multiFileResults: Array<Order & { filename: string; success: boolean }>
}

export type FollowUpRecommendation = Product & {
  followUpFirstReminderSmsId?: string
  followUpSecondReminderSmsId?: string
  orderId?: string
  order?: Order
}

export type SMSMessage = {
  to: string
  message: string
  sentDate: Date
  userId: string
}

export type AdminState = {
  adminAuth: { username: string; password: string }
  adminOnlyMode: boolean
  languageSwitching: boolean
  disableStore: boolean
  displayPrice: boolean
  runtimeSettings: RuntimeSetting[]
  users: User[]
  recentlyUpdatedRecommendations: boolean
  recentlyUpdatedBadges: boolean
  recentlyUpdatedResultCategories: boolean
  recentlyUpdatedFooterCategories: boolean
  recentlyUpdatedPages: boolean
  recentlyUpdatedStoreMaintenance: boolean
  recentlyUpdatedCriticalLimit: boolean
  recentlyUpdatedCMSDocuments: CmsDocuments
  apiStatus: ApiStatus
  orders: Order[]
  errors: string
  userPurged: boolean
  userPurgedFromAuth0: boolean
  appointments: Appointment[]
  languageOptions: { name: string; value: boolean }[]
  riskAcceptancePDF: ApiStateData<string>
  partnerBranches: PartnerBranch[] & { orders?: Order[] }
  allPartnerBranches: PartnerBranch[]
  blockedDays: BlockedDays
  allBlockedDays: BlockedDays[]
  smsSent: ApiStateData<boolean>
  latestSmsSent: ApiStateData<SMSMessage>
}

export type VoucherState = {
  apiStatus: ApiStatus
  vouchers: VoucherDefinition[]
  voucher: Partial<VoucherDefinition>
  voucherCodes: VoucherCode[]
  voucherCode: VoucherCode
  voucherError: string
  voucherCodeError: string
  voucherRedeemError: string
  voucherRedemptionLog: VoucherRedemptionLog[]
}

export type SupportState = {
  apiStatus: ApiStatus
  errors?: string
  supports: Support[]
}

export type VoucherDefinition = {
  key: string
  name: string
  voucherCodeIds: string[]
  voucherCodes?: VoucherCode[]
  productGroups?: string[]
  label: Translation
  discountPercentage?: number
  discountAmount?: number
  maxDiscountLimit?: number
  redemptionLimit: number
  expiration: Date
  published: boolean
  active: boolean
  supportKey: string
} & BaseEntity

export type VoucherCode = {
  _id: string
  code: string
  active: boolean
  redemptionLogsNo?: number
} & BaseEntity

export type VoucherRedemptionLog = {
  orderTransactionNo: string
  voucherDefinitionId: string
  voucherCodeId: string
  discountAmount: number
} & BaseEntity

export enum VerifyCodeStatus {
  Initial,
  Success,
  Wrong,
  Block,
  BlockIp,
  TooManyRequest,
}

export enum AccountStatus {
  Initial,
  Verify,
  TemporaryBlock,
  BlockedIp,
  Failed,
}

export type AuthToken = {
  accessToken: string
  idToken: string
  expiresIn: string
}

export type AuthState = {
  apiStatus: ApiStatus
  code: string
  phoneNo: string
  firstName: string
  lastName: string
  isVerified: VerifyCodeStatus
  codeSent: boolean
  error: string
  expiryTime: number
  authToken: AuthToken
  redirectUrl: string
  signedIn: boolean
  user: User
  errorMessage: string
  accountStatus: AccountStatus
  expiredBlockingAccout: Date
}

export enum PaymentMethod {
  ECA = 'MasterCard',
  VIS = 'Visa',
  AMX = 'American Express',
  CUP = 'UnionPay',
  DIN = 'Diners',
  DIS = 'Discover',
  JCB = 'JCB',
  MAU = 'Maestro',
  DNK = 'Dankort',
  UAP = 'Airplus',
  BON = 'Boncard',
  MYO = 'Manor MyOne',
  MMS = 'Mediamarkt Shopping Card',
  AZP = 'Amazon Pay',
  APL = 'Apple Pay',
  ACC = 'Availabill',
  INT = 'Byjuno',
  DVI = 'CRIF',
  CFY = 'Cryptocurrencies',
  EPS = 'EPS',
  GEP = 'EPS',
  PAY = 'Google Pay',
  GPA = 'Giropay',
  DEA = 'iDEAL',
  KLN = 'Klarna',
  MDP = 'Migros Bank E-Pay',
  PAP = 'PayPal',
  PSC = 'Paysafecard',
  PFC = 'PostFinance Card',
  PEF = 'PostFinance E-Finance',
  MFX = 'Powerpay',
  MFG = 'Powerpay Authorization',
  MFA = 'Powerpay Credit Check',
  MPX = 'Paycard',
  REK = 'Reka',
  SAM = 'Samsung Pay',
  ELV = 'SEPA',
  DIB = 'Sofort',
  SWB = 'Swissbilling',
  ESY = 'Swisscom Pay',
  SWP = 'SwissPass',
  TWI = 'Twint',
}

export enum PaymentStatus {
  SETTLED = 'settled',
  CANCELED = 'canceled',
  REFUNDED = 'refunded',
  NONAPPLICABLE = 'N/A',
}

export type Support = {
  name: string
  key: string
  uuid: string
  description: Translation
  isDefault: boolean
  link?: string
  logoPath?: string
  mailTo: string
  active?: boolean
} & BaseEntity

export enum EventVerifyOTP {
  TO_USERINFO,
  TOKEN_ACCESS,
  CODE_NOT_SEND,
}

export enum EventFetchingData {
  INITIAL_DATA,
  ADD_DATA,
}

export type GroupOfTimeSlots = {
  statusLoadingTimeSlot: string
  multiDaystimeSlots: MultiDaysTimeSlot[]
}

export enum StatusLoadingTimeSlot {
  Continue = 'continue',
  End = 'end',
}

export type ErrorHandler = {
  statusCode: number
  error: string
  message: string
  field?: string
}

export enum TagAppoinment {
  rikai = 'Rikai',
  Homesampling = 'Homesampling',
}

export type OrderSessionData = {
  _id: string
  isDoneInstruction: boolean
}

export enum ProductTab {
  direct,
  partner,
  homesampling,
}

export type FollowUpRecommendationsPayload = {
  followUp: Product
  isSelected: boolean
}

export enum IronKey {
  IRON_LOW = 'iron-low',
  IRON_HIGH = 'iron-high',
}
export enum ProductGroups {
  Bronze = 'bronze',
  Pilot = 'pilot',
  Silver = 'silver',
  Homesampling = 'homesampling',
}

export enum DisclaimerType {
  HasQuestionnaire = 'hasQuestionnaire',
}

export enum ChartType {
  ThreeLine = 'threeLine',
  FourLine = 'fourLine',
}
