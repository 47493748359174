import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiStatus } from '../../types'
import api from '../../api/crm'
import { CrmState } from '../../../admin/src/types'

const initialState: CrmState = {
  apiStatus: ApiStatus.idle,
  userActivities: [],
}

const asyncReducers = {
  getUserActivities: createAsyncThunk('crm/getUserActivities', async (userId: string) => {
    const response = await api.get(`/crm/user-activity/${userId}`)
    return response.data
  }),
}

const crmSlice = createSlice({
  name: 'crm',
  initialState,
  reducers: {
    initialCrmState: () => {
      return initialState
    },
    resetApiStatus: (state: CrmState) => {
      return {
        ...state,
        apiStatus: ApiStatus.idle,
      }
    },
    'getUserActivities/pending': (state: CrmState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getUserActivities/fulfilled': (state: CrmState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        userActivities: action.payload,
      }
    },
    'getUserActivities/rejected': (state: CrmState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
  },
})

export const { getUserActivities } = asyncReducers

export const { initialCrmState, resetApiStatus } = crmSlice.actions

export default crmSlice.reducer
