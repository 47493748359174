import { FunctionComponent, lazy } from 'react'
import { Route } from 'react-router-dom'
import AuthPaths from '@rikaiportal/shared/modules/auth/AuthPaths'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const PhoneNumberInput = lazy(() => delayedImport('auth/PhoneNumberInput', ROUTE_CONFIG))
const VerifyNumber = lazy(() => delayedImport('auth/VerifyNumber', ROUTE_CONFIG))

const AuthRoutes: FunctionComponent = () => {
  return (
    <>
      <Route exact path={AuthPaths.AUTH_LOGIN} render={() => <PhoneNumberInput />} />
      <Route exact path={AuthPaths.AUTH_VERIFY} component={VerifyNumber} />
    </>
  )
}

export default AuthRoutes
