import { VerifyCodeStatus } from '../../../../types'

export function statusToVerifyCode(status: number): VerifyCodeStatus {
  switch (status) {
    case 403: {
      return VerifyCodeStatus.Block
    }
    case 429: {
      return VerifyCodeStatus.TooManyRequest
    }

    case 401: {
      return VerifyCodeStatus.Wrong
    }

    case 200: {
      return VerifyCodeStatus.Success
    }

    default: {
      return VerifyCodeStatus.Wrong
    }
  }
}
