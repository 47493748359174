import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api/customer'
import { ApiStatus, SupportState } from '../../types'

const initialState: SupportState = {
  apiStatus: ApiStatus.idle,
  supports: [],
}

const asyncReducers = {
  getSupports: createAsyncThunk('supports/getSupports', async () => {
    const response = await api.get(`/supports`)
    return response.data
  }),
}

const supportSlice = createSlice({
  name: 'supports',
  initialState,
  reducers: {
    initailSupportState: () => {
      return initialState
    },
    'getSupports/pending': (state: SupportState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getSupports/fulfilled': (state: SupportState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        supports: action.payload,
      }
    },
    'getSupports/rejected': (
      state: SupportState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, error: action.error.message }
    },
  },
})

export const { getSupports } = asyncReducers

export const { initailSupportState } = supportSlice.actions

export default supportSlice.reducer
