import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api/customer'
import authApi from '../../api/auth'
import { ApiStatus, Application, UserState } from '../../types'

const initialState: UserState = {
  currentUser: null,
  apiStatus: ApiStatus.idle,
  error: null,
  allUsers: null,
  user: null,
  isUpdated: null,
}

const asyncReducers = {
  getUser: createAsyncThunk('users/getUser', async (userId: string) => {
    const response = await api.get(`/users/${userId}`)

    return response.data
  }),
  getUserById: createAsyncThunk('users/getUserById', async (userId: string) => {
    const response = await api.get(`/users/${userId}`)
    return response.data
  }),
  getUsers: createAsyncThunk('users/getUsers', async (applications: Application[]) => {
    const response = await api.get('/users', { params: { applications } })
    return response.data
  }),
  getCurrentUser: createAsyncThunk('users/getCurrentUser', async (idToken: string) => {
    const response = await authApi.post('/auth/user', {
      idToken,
    })

    return response.data
  }),
  saveUser: createAsyncThunk(
    'users/saveUser',
    async (params: {
      firstName: string
      phoneNo: string
      lastName: string
      preferredLanguage: string
    }) => {
      const { firstName, phoneNo, lastName, preferredLanguage } = params

      const response = await api.post('/users', {
        firstName,
        phoneNo,
        lastName,
        preferredLanguage,
      })
      return response.data
    }
  ),
  updateUser: createAsyncThunk(
    'users/updateUser',
    async (params: { field: string; value: string | boolean | Date; id: string }) => {
      const { field, value, id } = params
      const response = await api.put(`/users/${id}`, {
        [field]: value,
      })
      return response.data
    }
  ),
  updateUserById: createAsyncThunk(
    'users/updateUserById',
    async (params: {
      field?: string
      value?: string | boolean | Date
      id: string
      data?: Record<string, unknown>
    }) => {
      const { field, value, id, data } = params
      const response = await api.put(`/users/${id}/crm`, {
        [field]: value,
        ...data,
      })
      return response.data
    }
  ),
  updateUserPhoto: createAsyncThunk(
    'users/updateUserPhoto',
    async (params: { fd: FormData; id: string }) => {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      const { fd, id } = params
      const response = await api.put(`/users/${id}/photo`, fd, config)
      return response.data
    }
  ),
  updateOpenedAtDate: createAsyncThunk('users/updateOpenedAtDate', async (id: string) => {
    const response = await api.put(`/users/${id}/open-date`)
    return response.data
  }),
  closeAccount: createAsyncThunk('users/closeAccount', async (userTd: string) => {
    const response = await api.put(`/users/${userTd}/closeaccount`)
    return response.data
  }),
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    initialUserState: () => {
      return initialState
    },
    saveCurrentUser: (userState: UserState, action) => {
      return { ...userState, currentUser: action.payload }
    },
    resetErrors: (userState: UserState) => {
      return {
        ...userState,
        error: '',
      }
    },
    'getUser/pending': (userState: UserState) => {
      return { ...userState, apiStatus: ApiStatus.pending }
    },
    'getUser/fulfilled': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
        currentUser: { ...action.payload, roles: userState.currentUser?.roles },
      }
    },
    'getUserById/pending': (userState: UserState) => {
      return { ...userState, apiStatus: ApiStatus.pending }
    },
    'getUserById/fulfilled': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
        user: { ...action.payload, roles: userState.currentUser?.roles },
      }
    },
    'getUsers/pending': (userState: UserState) => {
      return { ...userState, apiStatus: ApiStatus.pending }
    },
    'getUsers/fulfilled': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
        allUsers: action.payload,
      }
    },
    'getCurrentUser/pending': (userState: UserState) => {
      return { ...userState, apiStatus: ApiStatus.pending }
    },
    'getCurrentUser/fulfilled': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
        currentUser: action.payload,
      }
    },
    'saveUser/pending': (userState: UserState) => {
      return { ...userState, apiStatus: ApiStatus.pending }
    },
    'saveUser/fulfilled': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
        currentUser: action.payload,
      }
    },
    'updateUser/pending': (userState: UserState) => {
      return {
        ...userState,
        isUpdated: false,
        apiStatus: ApiStatus.pending,
      }
    },
    'updateUser/fulfilled': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
        isUpdated: true,
        currentUser: { ...action.payload, roles: userState.currentUser?.roles },
      }
    },
    'updateUser/rejected': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.rejected,
        isUpdated: false,
        error: action.payload,
      }
    },
    'updateUserById/pending': (userState: UserState) => {
      return {
        ...userState,
        isUpdated: false,
        apiStatus: ApiStatus.pending,
      }
    },
    'updateUserById/fulfilled': (userState: UserState, action) => {
      return {
        ...userState,
        isUpdated: true,
        apiStatus: ApiStatus.fulfilled,
        user: { ...action.payload },
      }
    },
    'updateUserById/rejected': (userState: UserState, action) => {
      return {
        ...userState,
        isUpdated: false,
        apiStatus: ApiStatus.rejected,
        error: action.payload,
      }
    },
    'updateUserPhoto/pending': (userState: UserState) => {
      return {
        ...userState,
        apiStatus: ApiStatus.pending,
      }
    },
    'updateUserPhoto/fulfilled': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
        currentUser: { ...action.payload, roles: userState.currentUser?.roles },
      }
    },
    'updateUserPhoto/rejected': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.rejected,
        error: action.payload,
      }
    },
    'updateOpenedAtDate/pending': (userState: UserState) => {
      return {
        ...userState,
        apiStatus: ApiStatus.pending,
      }
    },
    'updateOpenedAtDate/fulfilled': (userState: UserState) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'closeAccount/rejected': (userState: UserState, action) => {
      return {
        ...userState,
        apiStatus: ApiStatus.rejected,
        error: action.payload,
      }
    },
    'closeAccount/pending': (userState: UserState) => {
      return {
        ...userState,
        apiStatus: ApiStatus.pending,
      }
    },
    'closeAccount/fulfilled': (userState: UserState) => {
      return {
        ...userState,
        apiStatus: ApiStatus.fulfilled,
      }
    },
  },
})

export const {
  saveUser,
  getUser,
  getUserById,
  getUsers,
  getCurrentUser,
  updateUser,
  updateUserPhoto,
  updateOpenedAtDate,
  updateUserById,
  closeAccount,
} = asyncReducers

export const { initialUserState, saveCurrentUser, resetErrors } = userSlice.actions

export default userSlice.reducer
