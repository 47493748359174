import { AxiosRequestConfig } from 'axios'
import { UnaryFunction } from '../types/unary-function.type'

/**
 * Creates an interceptor that authorization header to every request
 * when token retrieved by provided function exist
 * @param getAuthToken function that returns authorization token
 * @example
 *
 * const axiosInstance = axios.create({
 *   baseURL: 'http://example.com',
 * })
 *
 * function getToken(): string {
 *   return 'token'
 * }
 *
 * const authenticationInterceptor = createAuthorizationInterceptor(getToken)
 *
 * axiosInstance.interceptors.request.use(authenticationInterceptor)
 *
 */
export function createAuthorizationInterceptor(
  getToken: () => string
): UnaryFunction<AxiosRequestConfig, AxiosRequestConfig> {
  return (request: AxiosRequestConfig) => {
    const token = getToken()

    if (token) {
      request.headers.Authorization = `Bearer ${token}`
    }

    return request
  }
}
