/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '../../api/customer'
import mapOlderSurveyAnswers from '../../lib/mapOlderSurveyAnswer'
import {
  ApiStatus,
  FollowUpRecommendationsPayload,
  InternalNoteActivityType,
  PreviousReportLink,
  Product,
  ResultState,
  TestResult,
} from '../../types'
import { mapOlderTestRecommendationLinks } from '../../lib/mapOldRecommendationLinks'

const initialState: ResultState = {
  customerTestResults: [],
  customerTestResultsByOrder: null,
  resultCategories: [],
  healthMeasures: [],
  bloodMeasures: [],
  apiStatus: ApiStatus.idle,
  matchedOrders: [],
  historicalTestResultsByCustomer: {
    status: ApiStatus.idle,
    data: [],
  },
  distinctPreviousBloodMeasures: [],
  previousBloodMeasures: [],
  resultRecommendations: [],
  followUpRecommendations: [],
  healthMeasuresResult: [],
  resultCategoryResult: [],
  error: null,
  resultSaved: null,
  quickUploadSuccess: null,
  pdfUploadSuccess: [],
  pdfUploadError: [],
  pdfUploadExists: [],
  pdfData: '',
  pdfEncoded: null,
  resultPdf: '',
  previousReportLink: null,
  tempResult: null,
  multiFileResults: [],
  filename: '',
}

const asyncReducers = {
  getResultCategories: createAsyncThunk('results/getResultCategories', async () => {
    const response = await api.get('/result-categories')
    return response.data
  }),
  getHealthMeasures: createAsyncThunk('results/getHealthMeasures', async () => {
    const response = await api.get('/health-measures')
    return response.data
  }),
  getBloodMeasures: createAsyncThunk('results/getBloodMeasures', async () => {
    const response = await api.get('/blood-measures')
    return response.data
  }),
  getResultRecommendations: createAsyncThunk('results/getResultRecommendations', async () => {
    const response = await api.get('/recommendations')
    return response.data
  }),
  getResultFollowUps: createAsyncThunk('results/getResultFollowUps', async () => {
    const response = await api.get('/follow-ups')
    return response.data
  }),
  getCustomerTestResults: createAsyncThunk(
    'results/getCustomerTestResults',
    async (customerId: string) => {
      const response = await api.get(`/results/customer/${customerId}`)
      return response.data
    }
  ),
  getCustomerTestResultsByOrder: createAsyncThunk(
    'results/getCustomerTestResultsByOrder',
    async (orderId: string) => {
      const response = await api.get(`/results/order/${orderId}`)
      return response.data
    }
  ),
  getHistoricalTestResultsByCustomer: createAsyncThunk(
    'results/getHistoricalTestResultsByCustomer',
    async (params: { testResultId: string }) => {
      const { testResultId } = params
      const response = await api.get(`/results/historical-results/${testResultId}`)
      return response.data
    }
  ),
  getPreviousBloodMeasures: createAsyncThunk(
    'results/getPreviousBloodMeasures',
    async (params: { testId: string }) => {
      const response = await api.get(`/results/previous-blood-measures/${params.testId}`)
      return response.data
    }
  ),
  saveTestResult: createAsyncThunk('results/saveTestResult', async (params: TestResult) => {
    const response = await api.put(`/results/${params._id}`, {
      resultCategories: params.resultCategories,
      healthMeasuresResult: params.healthMeasuresResult,
      recommendations: params.recommendations,
      followUpRecommendations: params.followUpRecommendations || [],
    })
    return response.data
  }),
  saveCreatingUserTestResult: createAsyncThunk(
    'results/saveCreatingUserTestResult',
    async (params: { testResultId: string; user: string }) => {
      const { testResultId, user } = params
      const { data } = await api.put<TestResult>(`/results/${testResultId}/createdBy`, {
        createdBy: user,
      })
      return data
    }
  ),
  releaseTestResult: createAsyncThunk('results/releaseTestResult', async (params: TestResult) => {
    const response = await api.post(
      `/release-result/${params._id}`,
      {
        resultCategories: params.resultCategories,
        healthMeasuresResult: params.healthMeasuresResult,
        recommendations: params.recommendations,
        releasedBy: params.releasedBy,
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
    return response.data
  }),
  uploadHL7Result: createAsyncThunk(
    'results/uploadHL7Result',
    async (params: { fd: FormData; orderId: string }) => {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      const { fd, orderId } = params
      const response = await api.post(`/results/${orderId}/hl7`, fd, config)
      return response.data
    }
  ),
  mapHl7ToOrder: createAsyncThunk(
    'results/mapHl7ToOrder',
    async (params: { fd: FormData; filename: string; multipleFileUpload: boolean }) => {
      try {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }
        const { fd, filename, multipleFileUpload } = params
        const response = await api.post(
          `/results/hl7-upload/?filename=${encodeURIComponent(
            filename
          )}&multipleFileUpload=${multipleFileUpload}`,
          fd,
          config
        )
        return response.data
      } catch (err) {
        const customError = {
          name: 'Custom hl7 error',
          message: err.response.data.message,
          data: err.response.data,
        }
        throw customError
      }
    }
  ),

  uploadPDFReport: createAsyncThunk(
    'results/uploadPDFReport',
    async (params: { fd: FormData; pdfName: string; overwrite: boolean }) => {
      try {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }
        const { fd, pdfName, overwrite } = params
        const response = await api.put(
          `/results/pdf-upload/${overwrite ? 1 : 0}/${pdfName}`,
          fd,
          config
        )
        return response.data
      } catch (err) {
        if (err.response.data.statusCode === 409) {
          const customError = {
            name: 'Exists',
            message: err.response.data.message,
            statusCode: err.response.data.statusCode,
          }
          throw customError
        }
        const customError = {
          name: 'PDF Upload Error',
          message: err.response.data.message,
          statusCode: err.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  decryptPdf: createAsyncThunk('results/decryptPdf', async (params: { pdfPath: string }) => {
    const data = { ...params }
    const response = await api.post('/results/decrypt', data)
    return response.data
  }),
  updateInternalNote: createAsyncThunk(
    'results/updateInternalNote',
    async (params: {
      testResult: TestResult
      noteId: string
      userId: string
      note: string
      activityType: InternalNoteActivityType
    }) => {
      const response = await api.put(`/results/${params.testResult._id}/internalnote`, {
        activityType: params.activityType,
        internalNote: {
          _id: params.activityType === InternalNoteActivityType.ADD ? null : params.noteId,
          userId: params.userId,
          note: params.note,
        },
      })
      return response.data
    }
  ),
  downloadResultPDF: createAsyncThunk(
    'results/downloadResultPDF',
    async (params: { orderNo: string }) => {
      const response = await api.get('/results/download/test-result-file', {
        params,
      })
      return response.data
    }
  ),
  downloadResultPDFByTestResultId: createAsyncThunk(
    'results/downloadResultPDFByTestResultId',
    async (params: { testResultId: string }) => {
      const response = await api.get(`/results/result-download/${params.testResultId}`)
      return response.data
    }
  ),
  fetchPreviousLinkReport: createAsyncThunk(
    'results/fetchPreviousLinkReport',
    async (params: { customerId: string; testResultId: string }) => {
      const { customerId, testResultId } = params
      const response = await api.get(`/results/previous-result`, {
        params: {
          customerId,
          testResultId,
        },
      })
      return response.data
    }
  ),
}

const resultSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    initialResultState: () => {
      return initialState
    },
    resetApiStatus: (state: ResultState) => {
      return {
        ...state,
        apiStatus: ApiStatus.idle,
      }
    },
    resetErrors: (state: ResultState) => {
      return {
        ...state,
        error: null,
        results: [],
        multiFileResults: [],
        matchedOrders: [],
        customerTestResultsByOrder: null,
      }
    },
    setSelfResultCategorySummary: (state: ResultState, action) => {
      return {
        ...state,
        customerTestResultsByOrder: {
          ...state.customerTestResultsByOrder,
          resultCategories: state.customerTestResultsByOrder.resultCategories?.map((category) => {
            if (category.resultCategoryId === action.payload.resultCategoryId) {
              return { ...category, resultCategorySummary: action.payload.description }
            }
            return category
          }),
        },
      }
    },
    setSelfComparisonDescriptions: (state: ResultState, action) => {
      return {
        ...state,
        customerTestResultsByOrder: {
          ...state.customerTestResultsByOrder,
          resultCategories: state.customerTestResultsByOrder.resultCategories?.map((category) => {
            if (category.resultCategoryId === action.payload.resultCategoryId) {
              return { ...category, selfComparisonDescriptions: action.payload.description }
            }
            return category
          }),
        },
      }
    },
    setHealthMeasureResult: (state: ResultState, action) => {
      return {
        ...state,
        customerTestResultsByOrder: {
          ...state.customerTestResultsByOrder,
          healthMeasuresResult: state.customerTestResultsByOrder.healthMeasuresResult.map(
            (healthmeasure) => {
              if (healthmeasure.healthMeasureId === action.payload.healthMeasureId) {
                return { ...healthmeasure, level: action.payload.level }
              }
              return healthmeasure
            }
          ),
        },
      }
    },
    setResultCategoryResult: (state: ResultState, action) => {
      return {
        ...state,
        customerTestResultsByOrder: {
          ...state.customerTestResultsByOrder,
          resultCategories: state.customerTestResultsByOrder.resultCategories.map((category) => {
            if (category.resultCategoryId === action.payload.resultCategoryId) {
              return { ...category, level: action.payload.level }
            }
            return category
          }),
        },
      }
    },
    setResultRecommendations: (state: ResultState, action) => {
      return {
        ...state,
        customerTestResultsByOrder: {
          ...state.customerTestResultsByOrder,
          recommendations: action.payload,
        },
      }
    },
    resetPDFUploaded: (state: ResultState) => {
      return {
        ...state,
        pdfUploadSuccess: [],
        pdfUploadError: [],
        pdfUploadExists: [],
        apiStatus: ApiStatus.idle,
      }
    },
    setCustomerTestResult: (state: ResultState, action) => {
      return {
        ...state,
        customerTestResults: action.payload,
      }
    },
    setFollowUpRecommendations: (
      state: ResultState,
      action: PayloadAction<FollowUpRecommendationsPayload>
    ) => {
      let updatedData: Product[]
      updatedData = state.customerTestResultsByOrder.followUpRecommendations.filter(
        (item) => item.key !== action.payload.followUp.key
      )
      if (action.payload.isSelected) {
        updatedData = [...updatedData, action.payload.followUp]
      }

      return {
        ...state,
        customerTestResultsByOrder: {
          ...state.customerTestResultsByOrder,
          followUpRecommendations: updatedData,
        },
      }
    },
    getDistinctPreviousBloodMeasures: (state: ResultState) => {
      const distinctPreviousBloodMeasures = state.customerTestResultsByOrder.bloodMeasures?.reduce(
        (bloodMeasure, currentValue) => {
          const item = state.previousBloodMeasures.find(
            (bm) => bm.bloodMeasureKey === currentValue.key
          )
          if (item) bloodMeasure.push(item)
          return bloodMeasure
        },
        []
      )
      return {
        ...state,
        distinctPreviousBloodMeasures,
      }
    },
    setResultPDF: (state: ResultState, action) => {
      return { ...state, tempResult: action.payload, apiStatus: ApiStatus.idle }
    },
    'getResultCategories/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getResultCategories/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        resultCategories: action.payload,
      }
    },
    'getHealthMeasures/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getHealthMeasures/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        healthMeasures: action.payload,
      }
    },
    'getBloodMeasures/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getBloodMeasures/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        bloodMeasures: action.payload,
      }
    },
    'getResultRecommendations/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getResultRecommendations/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        resultRecommendations: action.payload,
      }
    },
    'getResultFollowUps/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getResultFollowUps/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        followUpRecommendations: action.payload,
      }
    },
    'getCustomerTestResults/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getCustomerTestResults/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        customerTestResults: action.payload,
      }
    },
    'getCustomerTestResultsByOrder/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getCustomerTestResultsByOrder/fulfilled': (
      state: ResultState,
      action: PayloadAction<{ testResult: TestResult; pdf: string }>
    ) => {
      const updatedTestResult = mapOlderTestRecommendationLinks(action.payload.testResult)
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        customerTestResultsByOrder: updatedTestResult,
        bloodMeasures: action.payload.testResult.bloodMeasures,
        healthMeasures: action.payload.testResult.healthMeasures,
        resultCategories: action.payload.testResult.categories,
        resultCategoryResult: action.payload.testResult.resultCategories,
        healthMeasuresResult: action.payload.testResult.healthMeasuresResult,
      }
    },
    'getHistoricalTestResultsByCustomer/pending': (state: ResultState) => {
      return { ...state, historicalTestResultsByCustomer: { status: ApiStatus.pending, data: [] } }
    },
    'getHistoricalTestResultsByCustomer/fulfilled': (state: ResultState, action) => {
      if(!!action.payload){
        action.payload.forEach((historicalTestResult) => {
          mapOlderSurveyAnswers(historicalTestResult.customerSurvey.answers)
        })
      }
      return {
        ...state,
        historicalTestResultsByCustomer: {
          status: ApiStatus.fulfilled,
          data: action.payload,
        },
      }
    },
    'getPreviousBloodMeasures/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getPreviousBloodMeasures/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        previousBloodMeasures: action.payload,
      }
    },
    'saveTestResult/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'saveTestResult/fulfilled': (
      state: ResultState,
      action: PayloadAction<{ resultSaved: boolean; pdf: string }>
    ) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        resultSaved: action.payload.resultSaved,
      }
    },
    'saveTestResult/rejected': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'releaseTestResult/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'releaseTestResult/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        customerTestResultsByOrder: action.payload,
      }
    },
    'releaseTestResult/rejected': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'uploadHL7Result/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        customerTestResultsByOrder: action.payload,
        matchedOrders: [],
      }
    },
    'uploadHL7Result/rejected': (state: ResultState, action) => {
      return { ...state, apiStatus: ApiStatus.rejected, error: action.payload }
    },
    'mapHl7ToOrder/fulfilled': (state: ResultState, action) => {
      if (action.payload.orders.length === 0 && action.payload.success === false) {
        return {
          ...state,
          apiStatus: ApiStatus.fulfilled,
          matchedOrders: action.payload.orders,
          quickUploadSuccess: action.payload.success,
          error: `No matching customer for order`,
          filename: action.payload.filename,
          multiFileResults: (state.multiFileResults && [
            ...state.multiFileResults,
            {
              ...action.payload.orders,
              filename: action.payload.filename,
              success: action.payload.success,
            },
          ]) || [
            {
              ...action.payload.orders,
              filename: action.payload.filename,
              success: action.payload.success,
            },
          ],
        }
      }
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        matchedOrders: action.payload.orders,
        quickUploadSuccess: action.payload.success,
        filename: action.payload.filename,
        multiFileResults: (state.multiFileResults && [
          ...state.multiFileResults,
          {
            ...action.payload.orders,
            filename: action.payload.filename,
            success: action.payload.success,
          },
        ]) || [
          {
            ...action.payload.orders,
            filename: action.payload.filename,
            success: action.payload.success,
          },
        ],
      }
    },
    'mapHl7ToOrder/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'mapHl7ToOrder/rejected': (
      state: ResultState,
      action: { type: string; payload: unknown; error: { name: string; message: string } }
    ) => {
      console.log('rejected', action)
      return {
        ...state,
        apiStatus: ApiStatus.rejected,
        error: action.error.message,
        quickUploadSuccess: false,
        //@ts-ignore
        filename: action.payload.filename,
      }
    },
    'uploadPDFReport/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'uploadPDFReport/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        pdfUploadSuccess: [...state.pdfUploadSuccess, action.payload],
      }
    },
    'uploadPDFReport/rejected': (
      state: ResultState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return {
        ...state,
        apiStatus: ApiStatus.rejected,
        pdfUploadError:
          action.error.name !== 'Exists'
            ? [...state.pdfUploadError, action.error.message]
            : state.pdfUploadError,
        pdfUploadExists:
          action.error.name === 'Exists'
            ? [...state.pdfUploadExists, action.error.message]
            : state.pdfUploadExists,
      }
    },
    'decryptPdf/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'decryptPdf/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        pdfData: action.payload,
      }
    },
    'decryptPdf/rejected': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'updateInternalNote/rejected': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'updateInternalNote/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateInternalNote/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        customerTestResultsByOrder: {
          ...state.customerTestResultsByOrder,
          internalNotes: [...action.payload],
        },
      }
    },
    'downloadResultPDF/rejected': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'downloadResultPDF/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'downloadResultPDF/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        resultPdf: action.payload,
      }
    },
    'downloadResultPDFByTestResultId/pending': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'downloadResultPDFByTestResultId/fulfilled': (state: ResultState, action) => {
      return {
        ...state,
        tempResult: action.payload,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'downloadResultPDFByTestResultId/rejected': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'fetchPreviousLinkReport/pending': (state: ResultState) => {
      return {
        ...state,
        apiStatus: ApiStatus.pending,
      }
    },
    'fetchPreviousLinkReport/fulfilled': (
      state: ResultState,
      action: PayloadAction<PreviousReportLink>
    ) => {
      return { ...state, apiStatus: ApiStatus.fulfilled, previousReportLink: action.payload }
    },
    'fetchPreviousLinkReport/rejected': (state: ResultState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'saveCreatingUserTestResult/fulfilled': (
      state: ResultState,
      action: PayloadAction<TestResult>
    ) => {
      const testResult = { ...state.customerTestResults, createdBy: action.payload.createdBy }
      return { ...state, apiStatus: ApiStatus.fulfilled, customerTestResults: testResult }
    },
  },
})

export const {
  getResultCategories,
  getHealthMeasures,
  getBloodMeasures,
  getCustomerTestResults,
  getCustomerTestResultsByOrder,
  getHistoricalTestResultsByCustomer,
  saveTestResult,
  uploadHL7Result,
  getResultRecommendations,
  getResultFollowUps,
  releaseTestResult,
  mapHl7ToOrder,
  uploadPDFReport,
  decryptPdf,
  updateInternalNote,
  downloadResultPDF,
  fetchPreviousLinkReport,
  saveCreatingUserTestResult,
  getPreviousBloodMeasures,
  downloadResultPDFByTestResultId,
} = asyncReducers

export const {
  initialResultState,
  setSelfResultCategorySummary,
  setSelfComparisonDescriptions,
  setHealthMeasureResult,
  setResultCategoryResult,
  setResultRecommendations,
  resetApiStatus,
  resetErrors,
  resetPDFUploaded,
  setCustomerTestResult,
  setFollowUpRecommendations,
  getDistinctPreviousBloodMeasures,
  setResultPDF,
} = resultSlice.actions

export default resultSlice.reducer
