import { AxiosError } from 'axios'
import { DEFAULT_ERROR_MESSAGE } from '../../../../const/default-error-message.const'
import { TOKEN_NOT_FOUND } from '../../../../const/token-not-found-error-message.const'
import { setVerified } from '../../../../modules/auth/AuthReducers'
import { VerifyCodeStatus } from '../../../../types'
import { Credentials } from '../interfaces/credentials.interface'
import { SignUpUser } from '../interfaces/signup-user.interface'
import { UpdateProfilePictureParams } from '../interfaces/update-profile-picture-params.interface'
import { AuthenticationService } from '../services/authentication.service'
import { statusToVerifyCode } from '../services/otp.service'
import { AuthenticationThunk } from '../types/authentication-thunk.type'
import { UpdateUser } from '../types/update-user.type'
import { selectAuthToken, selectAuthTokenId, selectUser } from './authentication.selectors'
import {
  AuthenticationPartialState,
  getUser,
  getUserFail,
  getUserSuccess,
  login,
  loginFail,
  loginSuccess,
  logout,
  logoutFail,
  logoutSuccess,
  refreshToken,
  refreshTokenFail,
  refreshTokenSuccess,
  saveAuthToken,
  signup,
  signupFail,
  signupSuccess,
  updateProfilePicture,
  updateProfilePictureFail,
  updateProfilePictureSuccess,
  updateUser,
  updateUserFail,
  updateUserSuccess,
  verifyAuth,
  verifyAuthFail,
  verifyAuthSuccess,
} from './authentication.state'

export type VerifyAuthAction = ReturnType<
  typeof verifyAuth | typeof verifyAuthFail | typeof verifyAuthSuccess | typeof saveAuthToken
>

export type GetUserAction = ReturnType<typeof getUser | typeof getUserSuccess | typeof getUserFail>

export type LoginAction = ReturnType<typeof login | typeof loginSuccess | typeof loginFail>

export type LogoutAction = ReturnType<typeof logout | typeof logoutSuccess | typeof logoutFail>

export type UpdateUserAction = ReturnType<
  typeof updateUser | typeof updateUserSuccess | typeof updateUserFail
>

export type SignupAction = ReturnType<typeof signup | typeof signupSuccess | typeof signupFail>

export type UpdateProfilePictureAction = ReturnType<
  typeof updateProfilePicture | typeof updateProfilePictureSuccess | typeof updateProfilePictureFail
>

export type RefreshTokenAction = ReturnType<
  typeof refreshToken | typeof refreshTokenSuccess | typeof refreshTokenFail
>

export class AuthenticationEffect<TState extends AuthenticationPartialState> {
  constructor(private readonly authenticationService: AuthenticationService) {}

  verifyAuth(authTimeout: number): AuthenticationThunk<TState, VerifyAuthAction> {
    return async (dispatch, getState) => {
      try {
        dispatch(verifyAuth())
        const token = this.authenticationService.getToken()

        if (!token) {
          dispatch(verifyAuthFail(''))
          return
        }

        const isAuthExpired = this.authenticationService.isAuthExpired(authTimeout)

        if (isAuthExpired) {
          this.authenticationService.logout()
          this.authenticationService.deleteLastRequest()
          dispatch(verifyAuthFail(''))
          return
        }

        // for interceptor to attach authorization header
        dispatch(saveAuthToken(token))

        const user = await this.authenticationService.getUser()
        // token from storage will be different when has expired and refreshed
        const lastToken = selectAuthToken(getState())

        dispatch(verifyAuthSuccess({ user, token: lastToken }))
      } catch (e) {
        dispatch(verifyAuthFail(DEFAULT_ERROR_MESSAGE))
      }
    }
  }

  getUser(): AuthenticationThunk<TState, GetUserAction> {
    return async (dispatch, getState) => {
      dispatch(getUser())
      try {
        const token = selectAuthTokenId(getState())

        if (!token) {
          dispatch(getUserFail(TOKEN_NOT_FOUND))
          return
        }

        const user = await this.authenticationService.getUser()

        dispatch(getUserSuccess(user))
      } catch (e) {
        dispatch(getUserFail(DEFAULT_ERROR_MESSAGE))
      }
    }
  }

  updateUser(id: string, partialUser: UpdateUser): AuthenticationThunk<TState, UpdateUserAction> {
    return async (dispatch, getState) => {
      const user = selectUser(getState())

      // optimistic ui
      dispatch(updateUser({ ...user, ...partialUser }))

      try {
        await this.authenticationService.updateUser(id, partialUser)
        dispatch(updateUserSuccess())
      } catch (e) {
        // rollback
        dispatch(updateUserFail({ user, error: DEFAULT_ERROR_MESSAGE }))
      }
    }
  }

  login(
    credentials: Credentials
  ): AuthenticationThunk<TState, LoginAction | ReturnType<typeof setVerified>> {
    return async (dispatch) => {
      dispatch(login())

      try {
        const response = await this.authenticationService.login(credentials)

        this.authenticationService.saveAuthToken(response.token)
        dispatch(loginSuccess(response))
        // set status on auth reducer to avoid breaking components that depend on this
        dispatch(setVerified(VerifyCodeStatus.Success))
      } catch (e) {
        const error = e as AxiosError
        const status = statusToVerifyCode(error?.response?.status)
        dispatch(loginFail(DEFAULT_ERROR_MESSAGE))
        // set status on auth reducer to avoid breaking components that depend on this
        dispatch(setVerified(status))
      }
    }
  }

  logout(): AuthenticationThunk<TState, LoginAction> {
    return (dispatch) => {
      try {
        dispatch(logout())
        this.authenticationService.logout()
        dispatch(logoutSuccess())
      } catch (e) {
        dispatch(logoutFail(DEFAULT_ERROR_MESSAGE))
      }
    }
  }

  signup(payload: SignUpUser): AuthenticationThunk<TState, SignupAction> {
    return async (dispatch) => {
      dispatch(signup())

      try {
        const { user, ...token } = await this.authenticationService.signUp(payload)
        this.authenticationService.saveAuthToken(token)
        dispatch(signupSuccess({ token, user }))
      } catch (e) {
        dispatch(signupFail(DEFAULT_ERROR_MESSAGE))
      }
    }
  }

  updateProfilePicture(
    payload: UpdateProfilePictureParams
  ): AuthenticationThunk<TState, UpdateProfilePictureAction> {
    return async (dispatch) => {
      dispatch(updateProfilePicture())

      try {
        const updatedUser = await this.authenticationService.updateProfilePicture(payload)

        // force to burst cache because image is saved with same url
        const photoAvatarUrl = updatedUser.photoAvatarUrl
          ? `${updatedUser.photoAvatarUrl}?${Date.now()}`
          : updatedUser.photoAvatarUrl

        const user = {
          ...updatedUser,
          photoAvatarUrl,
        }

        dispatch(updateProfilePictureSuccess(user))
      } catch (e) {
        dispatch(updateProfilePictureFail(DEFAULT_ERROR_MESSAGE))
      }
    }
  }

  refreshToken(): AuthenticationThunk<TState, RefreshTokenAction> {
    return async (dispatch, getState) => {
      dispatch(refreshToken())
      const currentToken = selectAuthToken(getState())

      if (!currentToken) {
        dispatch(refreshTokenFail(TOKEN_NOT_FOUND))
        return
      }

      try {
        const token = await this.authenticationService.refreshToken(currentToken)
        this.authenticationService.saveAuthToken(token)

        dispatch(refreshTokenSuccess(token))
      } catch (e) {
        dispatch(refreshTokenFail(DEFAULT_ERROR_MESSAGE))
      }
    }
  }
}
