import { AuthToken, User } from '../../../../types'
import { AuthenticationResponse } from '../interfaces/authentication-response.interface'
import { Credentials } from '../interfaces/credentials.interface'

import { createAction, createReducer } from '@reduxjs/toolkit'

export const verifyAuth = createAction(`[Authentication] Verify Auth`)

export const verifyAuthSuccess = createAction<AuthenticationResponse>(
  `[Authentication] Verify Auth Success`
)

export const verifyAuthFail = createAction<string>(`[Authentication] Verify Auth Fail`)

export const login = createAction<Credentials>(`[Authentication] Login`)

export const loginSuccess = createAction<AuthenticationResponse>(`[Authentication] Login Success`)

export const loginFail = createAction<string>(`[Authentication] Login Fail`)

export const logout = createAction(`Authentication Logout`)

export const logoutSuccess = createAction(`[Authentication] Logout Success`)

export const logoutFail = createAction<string>(`[Authentication] Logout Fail`)

export const getUser = createAction(`[Authentication] Get User`)

export const getUserSuccess = createAction<User>(`[Authentication] Get User Success`)

export const getUserFail = createAction<string>(`[Authentication] Get User Fail`)

export const saveAuthToken = createAction<AuthToken>(`[Authentication] Save Token`)

export const updateUser = createAction<User>('[Authentication] Update User')

export const updateUserSuccess = createAction('[Authentication] Update User Success')

export const updateUserFail = createAction<{ user: User; error: string }>(
  '[Authentication] Update User Fail'
)

export const signup = createAction('[Authentication] Signup')

export const signupSuccess = createAction<AuthenticationResponse>('[Authentication] Signup Success')

export const signupFail = createAction<string>('[Authentication] Signup Fail')

export const updateProfilePicture = createAction('[Authentication] Update Profile Picture')

export const updateProfilePictureSuccess = createAction<User>(
  '[Authentication] Update Profile Picture Success'
)

export const updateProfilePictureFail = createAction<string>(
  '[Authentication] Update Profile Picture Fail'
)

export const refreshToken = createAction('[Authentication] Refresh Token')

export const refreshTokenSuccess = createAction<AuthToken>('[Authentication] Refresh Token Success')

export const refreshTokenFail = createAction<string>('[Authentication] Refresh Token Fail')

export const AUTHENTICATION_STATE_KEY = 'authentication'

export interface AuthenticationState {
  verified: boolean
  user: User | null
  token: AuthToken
  loading: boolean
  refreshing: boolean
  error: string
}

export interface AuthenticationPartialState {
  [AUTHENTICATION_STATE_KEY]: AuthenticationState
}

export const authenticationInitialState: AuthenticationState = {
  verified: false,
  user: null,
  token: null,
  loading: false,
  refreshing: false,
  error: '',
}

export const authenticationReducer = createReducer(authenticationInitialState, (builder) =>
  builder
    .addCase(verifyAuth, (state) => ({ ...state, loading: true }))
    .addCase(verifyAuthSuccess, (state, { payload: { user, token } }) => ({
      ...state,
      verified: true,
      user,
      token,
      loading: false,
    }))
    .addCase(verifyAuthFail, (state, { payload }) => ({
      ...state,
      verified: true,
      error: payload,
      loading: false,
    }))
    .addCase(saveAuthToken, (state, { payload }) => ({ ...state, token: payload }))
    .addCase(getUser, (state) => ({ ...state, loading: true }))
    .addCase(getUserSuccess, (state, { payload }) => ({
      ...state,
      user: payload,
      loading: false,
    }))
    .addCase(getUserFail, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
    .addCase(login, (state) => ({ ...state, loading: true }))
    .addCase(loginSuccess, (state, { payload: { user, token } }) => ({
      ...state,
      user,
      token,
      verified: true,
      loading: false,
    }))
    .addCase(loginFail, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
    .addCase(logout, (state) => ({ ...state, loading: true }))
    .addCase(logoutSuccess, (state) => ({
      ...state,
      token: null,
      user: null,
      loading: false,
    }))
    .addCase(logoutFail, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
    .addCase(updateUser, (state, { payload }) => ({
      ...state,
      user: payload,
    }))
    .addCase(updateUserFail, (state, { payload: { user, error } }) => ({
      ...state,
      user,
      error,
    }))
    .addCase(updateProfilePicture, (state) => ({ ...state, loading: true }))
    .addCase(updateProfilePictureSuccess, (state, { payload }) => ({
      ...state,
      user: payload,
      loading: false,
    }))
    .addCase(updateProfilePictureFail, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))
    .addCase(signup, (state) => ({ ...state, loading: true }))
    .addCase(signupSuccess, (state, { payload: { token, user } }) => ({
      ...state,
      loading: false,
      token,
      user,
      verified: true,
    }))
    .addCase(signupFail, (state, { payload }) => ({ ...state, loading: false, error: payload }))
    .addCase(refreshToken, (state) => ({ ...state, refreshing: true }))
    .addCase(refreshTokenSuccess, (state, { payload }) => ({
      ...state,
      token: payload,
      refreshing: false,
    }))
    .addCase(refreshTokenFail, (state, { payload }) => ({
      ...state,
      error: payload,
      refreshing: false,
    }))
)
