import { selectTokenRefreshing, selectAuthToken } from '../authentication-client/src/lib'
import { AppStore } from '../modules/store'
import { AuthToken } from '../types'

export function getTokenAfterRefresh(store: AppStore): Promise<AuthToken> {
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState()
      const refreshing = selectTokenRefreshing(state)

      if (!refreshing) {
        const token = selectAuthToken(store.getState())
        resolve(token)
        unsubscribe()
      }
    })
  })
}
