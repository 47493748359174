import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api/customer'
import { ApiStatus, CmsState } from '../../types'

const initialState: CmsState = {
  footerLinkCategories: [],
  pages: [],
  storeMaintenance: null,
  apiStatus: ApiStatus.idle,
  error: null,
}

const asyncReducers = {
  getFooterLinks: createAsyncThunk('cms/getFooterLinks', async () => {
    const response = await api.get('/cms/footer-links')
    return response.data
  }),
  getPages: createAsyncThunk('cms/getPages', async () => {
    const response = await api.get('/cms/pages')
    return response.data
  }),
  getStoreMaintenance: createAsyncThunk('cms/getStoreMaintenance', async () => {
    const response = await api.get('/cms/store-maintenance')
    return response.data
  }),
}

const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    initialCmsState: () => {
      return initialState
    },
    resetApiStatus: (state: CmsState) => {
      return {
        ...state,
        apiStatus: ApiStatus.idle,
      }
    },
    'getFooterLinks/pending': (state: CmsState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getFooterLinks/fulfilled': (state: CmsState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        footerLinkCategories: action.payload,
      }
    },
    'getFooterLinks/rejected': (state: CmsState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'getPages/pending': (state: CmsState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getPages/fulfilled': (state: CmsState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        pages: action.payload,
      }
    },
    'getPages/rejected': (state: CmsState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'getStoreMaintenance/pending': (state: CmsState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getStoreMaintenance/fulfilled': (state: CmsState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        storeMaintenance: action.payload,
      }
    },
    'getStoreMaintenance/rejected': (state: CmsState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
  },
})

export const { getFooterLinks, getPages, getStoreMaintenance } = asyncReducers

export const { initialCmsState, resetApiStatus } = cmsSlice.actions

export default cmsSlice.reducer
