import { configureStore } from '@reduxjs/toolkit'

import productsReducer from './products/ProductsReducer'
import usersReducer from './users/UsersReducer'
import surveysReducer from './surveys/SurveysReducer'
import ordersReducer from './orders/OrdersReducer'
import resultsReducer from './results/ResultsReducer'
import adminReducer from './admin/AdminReducer'
import authReducers, { AUTH_STATE_KEY } from './auth/AuthReducers'
import cmsReducers from './cmsContent/cmsReducer'
import CrmReducer from './crm/CrmReducer'
import VoucherReducer from './voucher/VoucherReducer'
import SupportsReducer from './supports/SupportsReducer'
import appointmentsReducer from './appointments/AppointmentsReducer'
import { authenticationClient } from '../authentication-client/src/lib'
import { LAYOUT_STATE_KEY, layoutReducer } from './layout/layout.reducer'

const store = configureStore({
  reducer: {
    [authenticationClient.STATE_KEY]: authenticationClient.reducer,
    [LAYOUT_STATE_KEY]: layoutReducer,
    products: productsReducer,
    users: usersReducer,
    surveys: surveysReducer,
    orders: ordersReducer,
    results: resultsReducer,
    admin: adminReducer,
    [AUTH_STATE_KEY]: authReducers,
    cms: cmsReducers,
    crm: CrmReducer,
    voucher: VoucherReducer,
    supports: SupportsReducer,
    appointments: appointmentsReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export default store
