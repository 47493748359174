import { createAction, createReducer, createSelector } from '@reduxjs/toolkit'

export const LAYOUT_STATE_KEY = 'layout'

export interface LayoutState {
  redirectUrl: string
}

export interface LayoutPartialState {
  [LAYOUT_STATE_KEY]: LayoutState
}

export const layoutInitialState: LayoutState = {
  redirectUrl: '',
}

export const saveRedirectUrl = createAction<string>('[Layout] Save Redirect Url')

export const layoutReducer = createReducer(layoutInitialState, (builder) =>
  builder.addCase(saveRedirectUrl, (state, { payload }) => ({ ...state, redirectUrl: payload }))
)

export const selectLayoutState = (state: LayoutPartialState) => state[LAYOUT_STATE_KEY]

export const selectRedirectUrl = createSelector(selectLayoutState, ({ redirectUrl }) => redirectUrl)
