import React, { ReactNode } from 'react'
import logger from '../../logger'

export interface ErrorBoundaryProps {
  children?: React.ReactNode
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: unknown): void {
    logger.error(error)
  }

  render(): ReactNode {
    return this.props.children
  }
}

export default ErrorBoundary
