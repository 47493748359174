import React, { FunctionComponent, lazy } from 'react'
import AdminPaths from '@rikaiportal/shared/modules/admin/AdminPaths'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import { UserRole } from '@rikaiportal/shared/types'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const AdminRuntime = lazy(() => delayedImport('admin/AdminRuntime', ROUTE_CONFIG))
const AdminContent = lazy(() => delayedImport('admin/AdminContent', ROUTE_CONFIG))
const AdminVoucher = lazy(() => delayedImport('admin/AdminVoucher', ROUTE_CONFIG))
const AdminUpload = lazy(() => delayedImport('admin/AdminUpload', ROUTE_CONFIG))
const AdminDownload = lazy(() => delayedImport('admin/AdminDownload', ROUTE_CONFIG))

const AdminPartnerManagement = lazy(() =>
  delayedImport('admin/AdminPartnerManagement', ROUTE_CONFIG)
)
const AdminPartnerBlockedDays = lazy(() =>
  delayedImport('admin/AdminPartnerBlockedDays', ROUTE_CONFIG)
)

const AdminRoutes: FunctionComponent = () => {
  return (
    <>
      <ProtectedRoute
        path={AdminPaths.ADMIN_RUNTIME}
        component={AdminRuntime}
        allow={[UserRole.ADMIN]}
      />
      <ProtectedRoute
        path={AdminPaths.ADMIN_CONTENT}
        component={AdminContent}
        allow={[UserRole.ADMIN, UserRole.CRM_ADMIN, UserRole.DOCTOR]}
      />
      <ProtectedRoute
        path={AdminPaths.ADMIN_VOUCHER}
        component={AdminVoucher}
        allow={[UserRole.ADMIN, UserRole.CRM_ADMIN]}
      />
      <ProtectedRoute
        path={AdminPaths.ADMIN_UPLOAD}
        component={AdminUpload}
        allow={[UserRole.ADMIN, UserRole.DOCTOR]}
      />
      <ProtectedRoute
        path={AdminPaths.ADMIN_DOWNLOAD}
        component={AdminDownload}
        allow={[UserRole.ADMIN, UserRole.DATA_EXPORT]}
      />
      <ProtectedRoute
        path={AdminPaths.ADMIN_PARTNER_BLOCK_DAYS}
        component={AdminPartnerBlockedDays}
        allow={[UserRole.CRM_ADMIN]}
      />
      <ProtectedRoute
        path={AdminPaths.ADMIN_PARTNER}
        component={AdminPartnerManagement}
        allow={[UserRole.CRM_ADMIN]}
      />
    </>
  )
}

export default AdminRoutes
