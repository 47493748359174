import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import api from '../../api/customer'
import config from '../../config'
import mapOlderSurveyAnswers from '../../lib/mapOlderSurveyAnswer'
import {
  Address,
  ApiStatus,
  Application,
  LoadingStatusItem,
  Order,
  OrderCsvData,
  OrderState,
  OrderStatus,
  RescheduleFee,
  SurveyStatus,
  TestResult,
  TimeSlot,
} from '../../types'
import { DEFAULT_ERROR_MESSAGE } from '../../const/default-error-message.const'
import { AxiosError } from 'axios'

const initialState: OrderState = {
  currentOrder: null,
  orders: { status: ApiStatus.idle, data: [] },
  lazyLoadOrderStatus: { status: ApiStatus.idle, data: LoadingStatusItem.More },
  customerOrders: null,
  apiStatus: ApiStatus.idle,
  invoicePdf: { status: ApiStatus.idle, data: null },
  error: null,
  deliveryAddress: { streetAddress: '', zipCode: '', city: '' },
  invoiceAddress: { streetAddress: '', zipCode: '', city: '' },
}

const asyncReducers = {
  getOrder: createAsyncThunk('orders/getOrder', async (params: { orderId: string }, thunkApi) => {
    try {
      const response = await api.get(`/orders/${params.orderId}`)
      return response.data
    } catch (e) {
      const error = e.response?.data?.message ?? DEFAULT_ERROR_MESSAGE
      return thunkApi.rejectWithValue(error)
    }
  }),
  getOrders: createAsyncThunk(
    'orders/getOrders',
    async (params: {
      application: Application
      status?: OrderStatus.PAID | OrderStatus.ANALYZING
      year?: string
    }) => {
      const response = await api.get('/orders', { params })

      return response.data
    }
  ),
  getOrdersForDoctorPanel: createAsyncThunk('orders/getOrdersForDoctorPanel', async () => {
    const response = await api.get('/orders-dashboard')

    return response.data
  }),
  getReleaseOrdersForDoctorPanel: createAsyncThunk<
    { status: string; orders: Order[] },
    { after: Date; limit: number }
  >('orders/getReleaseOrdersForDoctorPanel', async (params, thunkApi) => {
    try {
      const response = await api.get<{ status: string; orders: Order[] }>(
        '/orders-dashboard/released',
        { params }
      )
      return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }),
  getOrderPaymentTransaction: createAsyncThunk(
    'orders/getOrderPaymentTransaction',
    async (params: { orderId: string; transactionId: string }) => {
      const response = await api.get(`/orders/${params.orderId}/payment`, {
        params: {
          transactionId: params.transactionId,
          utcOffset: moment().utcOffset(),
        },
      })

      return response.data
    }
  ),
  releaseOrderTestResult: createAsyncThunk(
    'orders/releaseOrderTestResult',
    async (params: { orderId: string }) => {
      const response = await api.put(`/orders/${params.orderId}/release-result`)
      return response.data
    }
  ),
  getOrdersByCustomerId: createAsyncThunk(
    'orders/getCustomerOrders',
    async (params: { customerId: string }) => {
      const response = await api.get(`/customers/${params.customerId}/orders`)
      return response.data
    }
  ),
  downloadInvoicePdf: createAsyncThunk(
    'orders/downloadInvoicePdf',
    async (params: { orderNo?: string; rikaiTransactionNo?: string }) => {
      const response = await api.get(`/orders/download/invoice-file`, {
        params: {
          utcOffset: moment().utcOffset(),
          orderNo: params.orderNo,
          rikaiTransactionNo: params.rikaiTransactionNo,
        },
      })
      return response.data
    }
  ),
  saveOrderAddOns: createAsyncThunk(
    'orders/saveOrderAddOns',
    async (params: {
      orderId: string
      addOnIds: string[]
      rikaiTransactionNo?: string
      voucherCode?: string
    }) => {
      try {
        const data = { ...params, utcOffset: moment().utcOffset() }
        if (!data.voucherCode) delete data.voucherCode
        const response = await api.put(`/orders/${params.orderId}/addons`, data)

        return response.data
      } catch (e) {
        throw new Error(e.response.data?.message)
      }
    }
  ),
  saveFollowUpOrder: createAsyncThunk(
    'orders/saveFollowUpOrder',
    async (params: {
      customerId: string
      productId: string
      productPrice: number
      isFollowUp: boolean
      mainOrderId: string
      mainResultId: string
      deliveryAddress: Address
      invoiceAddress: Address
      months: number
    }) => {
      try {
        const response = await api.post(`/orders`, params)
        return response.data
      } catch (err) {
        const customError = {
          name: 'Custom saveFollowUpOrder error',
          message: err.response.data.message,
          data: err.response.data,
        }
        throw customError
      }
    }
  ),
  updateAddress: createAsyncThunk(
    'orders/updateAddress',
    async (params: { orderId: string; deliveryAddress: Address; invoiceAddress?: Address }) => {
      try {
        const response = await api.post(`/order/${params.orderId}/address`, params)
        return response.data
      } catch (err) {
        const customError = {
          name: 'Custom updateAddress error',
          message: err.response.data.message,
          data: err.response.data,
        }
        throw customError
      }
    }
  ),
  rescheduleOrderAppointment: createAsyncThunk(
    'orders/rescheduleOrderAppointment',
    async (params: {
      _id: string
      orderId: string
      partnerBranchId: string
      scheduleFrom: Date | string
      scheduleTo: Date | string
      userId: string
      crmAdmin?: boolean
    }) => {
      try {
        const response = await api.put(`/appointments/${params._id}/reschedule`, params)
        return response.data
      } catch (err) {
        const customError = {
          name: 'Custom rescheduleOrderAppointment error',
          message: err.response.data.message,
          data: err.response.data,
        }
        throw customError
      }
    }
  ),
  deleteOrder: createAsyncThunk(
    'orders/deleteOrder',
    async (params: { orderId: string; cancellationReasons: string; shouldRefund?: boolean }) => {
      try {
        const response = await api.put(`/orders/${params.orderId}/delete`, {
          cancellationReasons: params.cancellationReasons,
          shouldRefund: params.shouldRefund,
        })

        return response.data
      } catch (e) {
        throw new Error(e.response.data?.message)
      }
    }
  ),
  refundTransaction: createAsyncThunk(
    'orders/refundTransaction',
    async (params: {
      orderId: string
      refundReasons: string
      rikaiTransactionNo: string
      shouldRefund?: boolean
    }) => {
      try {
        const response = await api.put(`/orders/${params.orderId}/refund`, {
          refundReasons: params.refundReasons,
          rikaiTransactionNo: params.rikaiTransactionNo,
          shouldRefund: params.shouldRefund,
        })

        return response.data
      } catch (e) {
        throw new Error(e.response.data?.message)
      }
    }
  ),
  updateOrderStatus: createAsyncThunk(
    'orders/updateOrderStatus',
    async (params: { orderId: string; orderStatus: OrderStatus }) => {
      const data = { ...params }
      const response = await api.put(`/orders/${data.orderId}/status`, data)
      return response.data
    }
  ),
  updateOrderTermsAndConditions: createAsyncThunk(
    'orders/updateOrderTermsAndConditions',
    async (params: { orderId: string; seenTermsAndConditions: boolean }) => {
      const data = { ...params }
      const response = await api.put(`/orders/${data.orderId}/termsandconditions`, data)
      return response.data
    }
  ),
  applyVoucher: createAsyncThunk(
    'orders/applyVoucher',
    async (params: { orderId: string; rikaiTransactionNo: string; voucherCode: string }) => {
      try {
        const response = await api.post(
          `/orders/${params.orderId}/${params.rikaiTransactionNo}/apply-voucher`,
          { voucherCode: params.voucherCode }
        )
        return response.data
      } catch (err) {
        const customError = {
          name: err.response.data.message,
          message: err.response.data.message,
          statusCode: err.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  revokeVoucher: createAsyncThunk(
    'orders/revokeVoucher',
    async (params: { orderId: string; rikaiTransactionNo: string }) => {
      try {
        const response = await api.get(
          `/orders/${params.orderId}/${params.rikaiTransactionNo}/revoke-voucher`
        )
        return response.data
      } catch (err) {
        const customError = {
          name: err.response.data.message,
          message: err.response.data.message,
          statusCode: err.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  saveRescheduleTransaction: createAsyncThunk(
    'orders/saveRescheduleTransaction',
    async (params: {
      orderId: string
      rikaiTransactionNo?: string
      rescheduleFee: RescheduleFee
      appointment: TimeSlot
    }) => {
      try {
        const response = await api.post(`/orders/${params.orderId}/save-reschedule-transaction`, {
          ...params,
          utcOffset: moment().utcOffset(),
        })
        return response.data
      } catch (err) {
        const customError = {
          name: err.response.data.message,
          message: err.response.data.message,
          statusCode: err.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  importOrders: createAsyncThunk(
    'orders/importOrders',
    async (params: { fd: FormData; year: string; status?: OrderStatus; application }) => {
      try {
        const { fd, year, status, application } = params
        const reqConfig = {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            year,
            status,
            application,
          },
        }
        const response = await api.post(`/orders/csv-upload`, fd, reqConfig)
        return response.data
      } catch (err) {
        const customError = {
          name: 'import CSV error',
          message: err.response.data.message,
          data: err.response.data,
        }
        throw customError
      }
    }
  ),
  exportOrders: createAsyncThunk(
    'orders/exportOrders',
    async (params: {
      application: Application
      status?: OrderStatus.PAID | OrderStatus.ANALYZING
      year?: string
    }) => {
      const response = await api.get('/orders/csv-download', {
        params: {
          ...params,
          isExport: true,
        },
      })

      return response.data
    }
  ),
}

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    initialOrderState: () => {
      return initialState
    },
    setCurrentOrder: (orderState: OrderState, action) => {
      return { ...orderState, currentOrder: action.payload }
    },
    resetError: (orderState: OrderState) => {
      return { ...orderState, error: null }
    },
    resetInvoicePdf: (orderState: OrderState) => {
      return { ...orderState, invoicePdf: null }
    },
    updateTestResultOfOrders: (orderState: OrderState, action: PayloadAction<TestResult>) => {
      const { orders } = orderState

      const data = orders?.data
        ? orders.data.map((order) => {
            if (order?.testResult?._id === action.payload._id) {
              return { ...order, testResult: action.payload }
            }
            return order
          })
        : []
      return { ...orderState, orders: { ...orders, data } }
    },
    setOrderError: (orderState: OrderState, action: PayloadAction<string>) => {
      return { ...orderState, error: action.payload }
    },
    setDeliveryAddress: (orderState: OrderState, action: PayloadAction<Address>) => {
      return { ...orderState, deliveryAddress: action.payload }
    },
    setInvoiceAddress: (orderState: OrderState, action: PayloadAction<Address>) => {
      return { ...orderState, invoiceAddress: action.payload }
    },
    'getOrder/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'getOrder/fulfilled': (orderState: OrderState, action) => {
      if (action.payload.preparationSurvey?.status === SurveyStatus.COMPLETED) {
        mapOlderSurveyAnswers(action.payload.preparationSurvey.answers)
      }
      return { ...orderState, apiStatus: ApiStatus.fulfilled, currentOrder: action.payload }
    },
    'getOrder/rejected': (state, { payload }) => ({ ...state, error: payload }),
    'getOrders/pending': (orderState: OrderState) => {
      return { ...orderState, orders: { ...orderState.orders, status: ApiStatus.pending } }
    },
    'getOrders/fulfilled': (orderState: OrderState, action) => {
      return {
        ...orderState,
        orders: { ...orderState.orders, status: ApiStatus.fulfilled, data: action.payload },
      }
    },
    'allowedAutoShare/pending': (orderState: OrderState) => {
      return { ...orderState, orders: { ...orderState.orders, status: ApiStatus.pending } }
    },
    'allowedAutoShare/fulfilled': (orderState: OrderState, action) => {
      return {
        ...orderState,
        orders: { ...orderState.orders, status: ApiStatus.fulfilled, data: action.payload },
      }
    },
    'getOrdersForDoctorPanel/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending, orders: { status: ApiStatus.pending } }
    },
    'getOrdersForDoctorPanel/fulfilled': (orderState: OrderState, action) => {
      return {
        ...orderState,
        apiStatus: ApiStatus.fulfilled,
        orders: { status: ApiStatus.fulfilled, data: action.payload },
      }
    },
    'getReleaseOrdersForDoctorPanel/pending': (orderState: OrderState) => {
      return { ...orderState, lazyLoadOrderStatus: { status: ApiStatus.pending, data: null } }
    },
    'getReleaseOrdersForDoctorPanel/fulfilled': (
      orderState: OrderState,
      action: PayloadAction<{ status: LoadingStatusItem; orders: Order[] }>
    ) => {
      // const oldOrders = orderState.orders.data
      const orders = {
        status: orderState.orders.status,
        data: orderState.orders.data.concat(action.payload.orders),
      }

      return {
        ...orderState,
        lazyLoadOrderStatus: { status: ApiStatus.fulfilled, data: action.payload.status },
        orders,
      }
    },
    'getReleaseOrdersForDoctorPanel/rejected': (orderState: OrderState) => {
      return { ...orderState, lazyLoadOrderStatus: { status: ApiStatus.rejected } }
    },
    'getOrderPaymentTransaction/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'getOrderPaymentTransaction/fulfilled': (orderState: OrderState, action) => {
      return { ...orderState, apiStatus: ApiStatus.fulfilled, currentOrder: action.payload }
    },
    'releaseOrderTestResult/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'releaseOrderTestResult/fulfilled': (orderState: OrderState, action) => {
      return { ...orderState, apiStatus: ApiStatus.fulfilled, currentOrder: action.payload }
    },
    'getCustomerOrders/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'getCustomerOrders/fulfilled': (orderState: OrderState, action) => {
      return { ...orderState, apiStatus: ApiStatus.fulfilled, customerOrders: action.payload }
    },
    'saveOrderAddOns/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'saveOrderAddOns/fulfilled': (orderState: OrderState, action) => {
      return { ...orderState, apiStatus: ApiStatus.fulfilled, currentOrder: action.payload }
    },
    'saveOrderAddOns/rejected': (
      orderState: OrderState,
      action: { payload: unknown; error?: { message: string } }
    ) => {
      return { ...orderState, apiStatus: ApiStatus.rejected, error: action.error.message }
    },
    'saveFollowUpOrder/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'saveFollowUpOrder/fulfilled': (orderState: OrderState, action: PayloadAction<Order>) => {
      const { rikaiTransactionNo } =
        action.payload.transactions[action.payload.transactions.length - 1]

      window.location.href = `${config.api.customer}/orders/${action.payload._id}/${rikaiTransactionNo}/settle`
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'saveFollowUpOrder/rejected': (
      orderState: OrderState,
      action: { type: string; payload: unknown; error: { name: string; message: string } }
    ) => {
      return {
        ...orderState,
        apiStatus: ApiStatus.rejected,
        error: action.error.message,
      }
    },
    'updateAddress/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'updateAddress/fulfilled': (orderState: OrderState, action: PayloadAction<Order>) => {
      return { ...orderState, apiStatus: ApiStatus.fulfilled, currentOrder: action.payload }
    },
    'updateAddress/rejected': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.rejected }
    },
    'downloadInvoicePdf/pending': (orderState: OrderState) => {
      return {
        ...orderState,
        invoicePdf: {
          data: null,
          status: ApiStatus.pending,
        },
      }
    },
    'downloadInvoicePdf/fulfilled': (orderState: OrderState, action) => {
      return {
        ...orderState,
        apiStatus: ApiStatus.fulfilled,
        invoicePdf: {
          data: action.payload,
          status: ApiStatus.fulfilled,
        },
      }
    },
    'rescheduleOrderAppointment/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'rescheduleOrderAppointment/fulfilled': (orderState: OrderState, action) => {
      return {
        ...orderState,
        apiStatus: ApiStatus.fulfilled,
        currentOrder: { ...orderState.currentOrder, appointment: action.payload },
      }
    },
    'rescheduleOrderAppointment/rejected': (
      orderState: OrderState,
      action: { payload: unknown; error?: { message: string } }
    ) => {
      return { ...orderState, apiStatus: ApiStatus.rejected, error: action.error.message }
    },
    'deleteOrder/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'deleteOrder/fulfilled': (orderState: OrderState, action) => {
      return { ...orderState, apiStatus: ApiStatus.fulfilled, customerOrders: action.payload }
    },
    'deleteOrder/rejected': (
      orderState: OrderState,
      action: { payload: unknown; error?: { message: string } }
    ) => {
      return { ...orderState, apiStatus: ApiStatus.rejected, error: action.error?.message }
    },
    'refundTransaction/pending': (orderState: OrderState) => {
      return { ...orderState, apiStatus: ApiStatus.pending }
    },
    'refundTransaction/fulfilled': (orderState: OrderState, action) => {
      return { ...orderState, apiStatus: ApiStatus.fulfilled, currentOrder: action.payload }
    },
    'refundTransaction/rejected': (
      orderState: OrderState,
      action: { payload: unknown; error?: { message: string } }
    ) => {
      return { ...orderState, apiStatus: ApiStatus.rejected, error: action.error?.message }
    },
    'updateOrderStatus/pending': (state: OrderState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateOrderStatus/fulfilled': (state: OrderState, action) => {
      const orders = {
        status: state.orders.status,
        data: state.orders.data.map((order) => {
          if (action.payload._id === order._id) {
            return { ...order, status: action.payload.status }
          }
          return order
        }),
      }
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        currentOrder: { ...state.currentOrder, status: action.payload.status },
        orders,
      }
    },
    'updateOrderStatus/rejected': (state: OrderState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'updateOrderTermsAndConditions/pending': (state: OrderState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateOrderTermsAndConditions/fulfilled': (state: OrderState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        customerOrders: state.customerOrders?.map((order) => {
          if (action.payload._id === order._id) {
            return { ...order, seenTermsAndConditions: action.payload.seenTermsAndConditions }
          }
          return order
        }),
        currentOrder: {
          ...state.currentOrder,
          seenTermsAndConditions: action.payload.seenTermsAndConditions,
        },
      }
    },
    'updateOrderTermsAndConditions/rejected': (state: OrderState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'applyVoucher/pending': (state: OrderState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'applyVoucher/fulfilled': (state: OrderState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        currentOrder: { ...state.currentOrder, ...action.payload },
      }
    },
    'applyVoucher/rejected': (
      state: OrderState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, error: action.error.message }
    },
    'revokeVoucher/pending': (state: OrderState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'revokeVoucher/fulfilled': (state: OrderState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        currentOrder: { ...state.currentOrder, ...action.payload },
      }
    },
    'revokeVoucher/rejected': (
      state: OrderState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, error: action.error.message }
    },
    'saveRescheduleTransaction/pending': (state: OrderState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'saveRescheduleTransaction/fulfilled': (state: OrderState, action: PayloadAction<Order>) => {
      window.location.href = `${config.api.customer}/orders/${action.payload._id}/${action.payload.lastUpdatedTransactionNo}/settle`
      return { ...state, apiStatus: ApiStatus.pending, currentOrder: action.payload }
    },
    'saveRescheduleTransaction/rejected': (state: OrderState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'importOrders/pending': (orderState: OrderState) => {
      return { ...orderState, orderRows: { ...orderState.orderRows, status: ApiStatus.pending } }
    },
    'importOrders/fulfilled': (orderState: OrderState, action: PayloadAction<number>) => {
      return {
        ...orderState,
        orderRows: {
          ...orderState.orderRows,
          status: ApiStatus.fulfilled,
          data: action.payload,
        },
      }
    },
    'importOrders/rejected': (
      orderState: OrderState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return {
        ...orderState,
        orderRows: {
          ...orderState.orderRows,
          status: ApiStatus.rejected,
          errorMessage: action.error.message,
        },
      }
    },
    'exportOrders/pending': (orderState: OrderState) => {
      return {
        ...orderState,
        ordersCsv: {
          ...orderState.ordersCsv,
          status: ApiStatus.pending,
        },
      }
    },
    'exportOrders/fulfilled': (orderState: OrderState, action: PayloadAction<OrderCsvData[]>) => {
      return {
        ...orderState,
        ordersCsv: {
          ...orderState.ordersCsv,
          status: ApiStatus.fulfilled,
          data: action.payload,
        },
      }
    },
    'exportOrders/rejected': (
      orderState: OrderState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return {
        ...orderState,
        ordersCsv: {
          ...orderState.ordersCsv,
          status: ApiStatus.rejected,
          errorMessage: action.error.message,
        },
      }
    },
  },
})

export const {
  getOrder,
  getOrders,
  getOrdersForDoctorPanel,
  getReleaseOrdersForDoctorPanel,
  getOrderPaymentTransaction,
  releaseOrderTestResult,
  getOrdersByCustomerId,
  rescheduleOrderAppointment,
  deleteOrder,
  downloadInvoicePdf,
  updateOrderStatus,
  updateOrderTermsAndConditions,
  saveOrderAddOns,
  saveFollowUpOrder,
  updateAddress,
  refundTransaction,
  applyVoucher,
  revokeVoucher,
  saveRescheduleTransaction,
  importOrders,
  exportOrders,
} = asyncReducers

export const {
  initialOrderState,
  setCurrentOrder,
  resetError,
  resetInvoicePdf,
  updateTestResultOfOrders,
  setDeliveryAddress,
  setInvoiceAddress,
  setOrderError,
} = orderSlice.actions

export default orderSlice.reducer
