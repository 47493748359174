import React, { FunctionComponent, lazy } from 'react'
import DoctorPaths from '@rikaiportal/shared/modules/doctors/DoctorPaths'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import { UserRole } from '@rikaiportal/shared/types'
import { Switch } from 'react-router-dom'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const SurveyIndex = lazy(() => delayedImport('surveys/SurveyIndex', ROUTE_CONFIG))

const DoctorPanelDashboard = lazy(() => delayedImport('doctor/DoctorPanelDashboard', ROUTE_CONFIG))

const DoctorPanelOrderDetail = lazy(() =>
  delayedImport('doctor/DoctorPanelOrderDetail', ROUTE_CONFIG)
)

const DoctorPanelResultPreview = lazy(() =>
  delayedImport('doctor/DoctorPanelResultPreview', ROUTE_CONFIG)
)

const DoctorPanelResultView = lazy(() =>
  delayedImport('doctor/DoctorPanelResultView', ROUTE_CONFIG)
)

const SurveyQuestion = lazy(() => delayedImport('surveys/SurveyQuestion', ROUTE_CONFIG))

const SurveyQuestionSummary = lazy(() =>
  delayedImport('surveys/SurveyQuestionSummary', ROUTE_CONFIG)
)

const SurveyFinish = lazy(() => delayedImport('surveys/SurveyFinish', ROUTE_CONFIG))
const ResearchApproval = lazy(() => delayedImport('surveys/ResearchApproval', ROUTE_CONFIG))

const DoctorRoutes: FunctionComponent = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path={DoctorPaths.DOCTOR_PANEL_DASHBOARD}
        component={DoctorPanelDashboard}
        allow={[UserRole.DOCTOR, UserRole.ADMIN]}
      />
      <ProtectedRoute
        exact
        path={DoctorPaths.DOCTOR_PANEL_ORDER_DETAIL}
        component={DoctorPanelOrderDetail}
        allow={[UserRole.DOCTOR, UserRole.ADMIN]}
      />
      <ProtectedRoute
        exact
        path={DoctorPaths.DOCTOR_PANEL_RESULT_PREVIEW}
        component={DoctorPanelResultPreview}
        allow={[UserRole.DOCTOR, UserRole.ADMIN]}
      />
      <ProtectedRoute
        exact
        path={DoctorPaths.DOCTOR_PANEL_RESULT_VIEW}
        component={DoctorPanelResultView}
        allow={[UserRole.DOCTOR, UserRole.ADMIN]}
      />
      <ProtectedRoute
        exact
        path={DoctorPaths.SURVEY_INDEX}
        component={SurveyIndex}
        allow={[UserRole.DOCTOR]}
      />
      <ProtectedRoute
        exact
        path={DoctorPaths.SURVEY_QUESTION}
        component={SurveyQuestion}
        allow={[UserRole.DOCTOR]}
      />
      <ProtectedRoute
        exact
        path={DoctorPaths.SURVEY_QUESTION_SUMMARY}
        component={SurveyQuestionSummary}
        allow={[UserRole.DOCTOR]}
      />
      <ProtectedRoute
        exact
        path={DoctorPaths.RESEARCH_APPROVAL}
        component={ResearchApproval}
        allow={[UserRole.DOCTOR]}
      />
      <ProtectedRoute
        exact
        path={DoctorPaths.SURVEY_FINISH}
        component={SurveyFinish}
        allow={[UserRole.DOCTOR]}
      />
    </Switch>
  )
}

export default DoctorRoutes
