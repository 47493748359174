import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiStatus, AppointmentState, RescheduleFee, RescheduleFeeKeys } from '../../types'
import api from '../../api/customer'

const initialState: AppointmentState = {
  allRescheduleFees: { status: ApiStatus.idle, data: [] },
  currentRescheduleFee: { status: ApiStatus.idle, data: null },
  appointments: { status: ApiStatus.idle, data: [] },
  appointmentCSV: { status: ApiStatus.idle, data: null },
}

const asyncReducers = {
  fetchAllRescheduleFees: createAsyncThunk('appointments/getAllRescheduleFees', async () => {
    try {
      const response = await api.get('/appointments/reschedule-fees')
      return response.data
    } catch (err) {
      const customError = {
        name: 'get reschedule fees error',
        message: err.response.data.message,
        data: err.response.data,
      }
      throw customError
    }
  }),
  downloadAppointmentsForAdmin: createAsyncThunk(
    'appointments/downloadAppointmentsForAdmin',
    async (params: { startDate: string; endDate: string }) => {
      const { startDate, endDate } = params
      const response = await api.get('/admin/appointments/download', {
        params: {
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
        },
      })
      return response.data
    }
  ),
}

const appointmentSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setCurrentRescheduleFee: (
      appointmentState: AppointmentState,
      action: PayloadAction<RescheduleFeeKeys>
    ) => {
      const rescheduleFee = appointmentState?.allRescheduleFees?.data?.find(
        (fee) => fee.key === action.payload
      )
      return {
        ...appointmentState,
        currentRescheduleFee: {
          status: ApiStatus.fulfilled,
          data: rescheduleFee,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncReducers.fetchAllRescheduleFees.pending,
      (appointmentState: AppointmentState) => {
        return {
          ...appointmentState,
          allRescheduleFees: { status: ApiStatus.pending },
        }
      }
    )
    builder.addCase(
      asyncReducers.fetchAllRescheduleFees.fulfilled,
      (appointmentState: AppointmentState, action: PayloadAction<RescheduleFee[]>) => {
        return {
          ...appointmentState,
          allRescheduleFees: { status: ApiStatus.fulfilled, data: action.payload },
        }
      }
    )
    builder.addCase(
      asyncReducers.fetchAllRescheduleFees.rejected,
      (appointmentState: AppointmentState, action) => {
        return {
          ...appointmentState,
          allRescheduleFees: {
            status: ApiStatus.rejected,
            data: [],
            errorMessage: action.error.message,
          },
        }
      }
    )

    builder.addCase(
      asyncReducers.downloadAppointmentsForAdmin.pending,
      (appointmentState: AppointmentState) => {
        return {
          ...appointmentState,
          appointmentCSV: { data: null, status: ApiStatus.pending },
        }
      }
    )
    builder.addCase(
      asyncReducers.downloadAppointmentsForAdmin.rejected,
      (appointmentState: AppointmentState, action) => {
        return {
          ...appointmentState,
          appointmentCSV: {
            status: ApiStatus.rejected,
            data: null,
            errorMessage: action.error.message,
          },
        }
      }
    )
    builder.addCase(
      asyncReducers.downloadAppointmentsForAdmin.fulfilled,
      (appointmentState: AppointmentState, action) => {
        return {
          ...appointmentState,
          appointmentCSV: { status: ApiStatus.fulfilled, data: action.payload },
        }
      }
    )
  },
})

export const { fetchAllRescheduleFees, downloadAppointmentsForAdmin } = asyncReducers

export const { setCurrentRescheduleFee } = appointmentSlice.actions

export default appointmentSlice.reducer
