/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent } from 'react'
import { Route } from 'react-router-dom'
import { AuthenticationGuard } from '../../authentication-client/src/lib'
import { UserRole } from '../../types'

type RouteProps = {
  component: any // rendered component
  [propName: string]: any // route properties
  redirectURL?: string
  allow?: UserRole[]
}

const ProtectedRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  allow,
  redirectURL,
  ...props
}) => {
  return (
    <Route {...props}>
      <AuthenticationGuard redirectPath={redirectURL} roles={allow}>
        <Component />
      </AuthenticationGuard>
    </Route>
  )
}

export default ProtectedRoute
