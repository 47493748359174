import { AxiosInstance } from 'axios'
import { AuthenticationService } from './services/authentication.service'
import { LocalStorageService } from './services/local-storage.service'
import { AuthenticationEffect } from './state/authentication.effects'
import {
  AUTHENTICATION_STATE_KEY,
  AuthenticationPartialState,
  authenticationReducer,
} from './state/authentication.state'

// Two hours
export const AUTHENTICATION_TIMEOUT = 1000 * 60 * 60 * 2

/*
 * Authentication client at the moment the authentication contains the user
 * state and effects corresponding to it, authentication can be splitted to
 * be reused with out the need of a user
 */

export class AuthenticationClient<
  TState extends AuthenticationPartialState
> extends AuthenticationEffect<TState> {
  readonly reducer = authenticationReducer

  readonly STATE_KEY = AUTHENTICATION_STATE_KEY

  constructor(httpClient: AxiosInstance) {
    const storage = new LocalStorageService()
    const authenticationService = new AuthenticationService(storage, httpClient)

    super(authenticationService)
  }
}
