import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import api from '../../api/voucher'
import {
  ApiStatus,
  VoucherCode,
  VoucherDefinition,
  VoucherRedemptionLog,
  VoucherState,
} from '../../types'

const initialState: VoucherState = {
  apiStatus: ApiStatus.idle,
  vouchers: null,
  voucher: {
    label: {
      de: '',
      en: '',
      fr: '',
      it: '',
    },
    expiration: moment().add(1, 'years').toDate(),
    redemptionLimit: 1,
    published: false,
  },
  voucherCodes: null,
  voucherCode: null,
  voucherError: null,
  voucherRedeemError: null,
  voucherCodeError: null,
  voucherRedemptionLog: null,
}

const asyncReducers = {
  getVouchers: createAsyncThunk('voucher/getVouchers', async () => {
    const response = await api.get('/vouchers')

    return response.data
  }),
  createVoucher: createAsyncThunk(
    'voucher/createVoucher',
    async (data: Partial<VoucherDefinition>) => {
      try {
        const response = await api.post('/vouchers', data)

        return response.data
      } catch (error) {
        const customError = {
          name: error.response.data.message,
          message: error.response.data.message,
          statusCode: error.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  updateVoucher: createAsyncThunk(
    'voucher/updateVoucher',
    async (data: Partial<VoucherDefinition>) => {
      const newData = { ...data }
      delete newData?._id
      delete newData?.key
      delete newData?.voucherCodeIds
      delete newData?.voucherCodes
      delete newData?.ver
      delete newData?.updatedAt
      delete newData?.createdAt
      delete newData?.active
      delete newData?.validFrom
      delete newData?.validTo

      try {
        const response = await api.put(`/vouchers/${data._id}`, newData)

        return response.data
      } catch (error) {
        const customError = {
          name: error.response.data.message,
          message: error.response.data.message,
          statusCode: error.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  deleteVoucher: createAsyncThunk('voucher/deleteVoucher', async (voucherId: string) => {
    try {
      const response = await api.delete(`/vouchers/${voucherId}`)

      return response.data
    } catch (error) {
      const customError = {
        name: error.response.data.message,
        message: error.response.data.message,
        statusCode: error.response.data.statusCode,
      }
      throw customError
    }
  }),
  createVoucherCode: createAsyncThunk(
    'voucher/createVoucherCode',
    async (params: { voucherId: string; code: string }) => {
      try {
        const response = await api.post(`/vouchers/${params.voucherId}/codes`, {
          code: params.code,
        })

        return response.data
      } catch (err) {
        const customError = {
          name: err.response.data.message,
          message: err.response.data.message,
          statusCode: err.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  updateVoucherCode: createAsyncThunk(
    'voucher/updateVoucherCode',
    async (params: { voucherCodeId: string; voucherCode: VoucherCode }) => {
      try {
        const newData = { ...params.voucherCode }
        delete newData?._id
        delete newData?.active
        delete newData?.createdAt
        delete newData?.updatedAt
        delete newData?.validFrom
        delete newData?.validTo
        delete newData?.ver
        delete newData?.redemptionLogsNo

        const response = await api.put(`/voucher-codes/${params.voucherCodeId}`, newData)

        return response.data
      } catch (err) {
        const customError = {
          name: err.response.data.message,
          message: err.response.data.message,
          statusCode: err.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  deleteVoucherCode: createAsyncThunk(
    'voucher/deleteVoucherCode',
    async (voucherCodeId: string) => {
      try {
        const response = await api.delete(`/voucher-codes/${voucherCodeId}`)

        return response.data
      } catch (err) {
        const customError = {
          name: err.response.data.message,
          message: err.response.data.message,
          statusCode: err.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
  getRedemptionLog: createAsyncThunk('voucher/getRedemptionLog', async (voucherCodeId: string) => {
    const response = await api.get(`/voucher-codes/${voucherCodeId}/redeem`)

    return response.data
  }),
  redeemVoucher: createAsyncThunk('voucher/redeemVoucher', async (data: VoucherRedemptionLog) => {
    const response = await api.post(`/vouchers/redeem`, data)

    return response.data
  }),
  getVoucherDefinitionByCode: createAsyncThunk(
    'voucher/getVoucherDefinitionByCode',
    async (params: { code: string; productGroup?: string }) => {
      try {
        const response = await api.get(`/vouchers/check/${params.code}`, {
          params: { productGroup: params.productGroup },
        })

        return response.data
      } catch (error) {
        const customError = {
          name: error.response.data.message,
          message: error.response.data.message,
          statusCode: error.response.data.statusCode,
        }
        throw customError
      }
    }
  ),
}

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    initialVoucherState: () => {
      return initialState
    },
    setVoucher: (state: VoucherState, action) => {
      return { ...state, voucher: action.payload }
    },
    resetVoucher: (state: VoucherState) => {
      return { ...state, voucher: initialState.voucher }
    },
    setVoucherCodes: (
      state: VoucherState,
      action: {
        payload: VoucherCode[]
        type: string
      }
    ) => {
      return { ...state, voucherCodes: action.payload }
    },
    setVoucherCode: (
      state: VoucherState,
      action: {
        payload: VoucherCode
        type: string
      }
    ) => {
      return { ...state, voucherCode: action.payload }
    },
    'getVouchers/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getVouchers/fulfilled': (state: VoucherState, action) => {
      return { ...state, apiStatus: ApiStatus.fulfilled, vouchers: action.payload }
    },
    'getVouchers/rejected': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'createVoucher/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'createVoucher/fulfilled': (state: VoucherState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        vouchers: [...state.vouchers, action.payload],
      }
    },
    'createVoucher/rejected': (
      state: VoucherState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, voucherError: action.error.message }
    },
    'updateVoucher/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateVoucher/fulfilled': (state: VoucherState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        vouchers: state.vouchers.map((voucher) =>
          voucher.key === action.payload.key ? action.payload : voucher
        ),
      }
    },
    'updateVoucher/rejected': (
      state: VoucherState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, voucherError: action.error.message }
    },
    'deleteVoucher/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'deleteVoucher/fulfilled': (state: VoucherState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        vouchers: state.vouchers.filter((voucher) => voucher._id !== action.payload._id),
      }
    },
    'deleteVoucher/rejected': (
      state: VoucherState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, voucherError: action.error.message }
    },
    'createVoucherCode/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'createVoucherCode/fulfilled': (state: VoucherState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        vouchers: state.vouchers.map((voucher) =>
          voucher._id === action.payload._id ? action.payload : voucher
        ),
        voucherCodes: action.payload.voucherCodes,
      }
    },
    'createVoucherCode/rejected': (
      state: VoucherState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, voucherCodeError: action.error.message }
    },
    'updateVoucherCode/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateVoucherCode/fulfilled': (state: VoucherState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        voucherCodes: state.voucherCodes.map((code) =>
          code._id === action.payload._id ? action.payload : code
        ),
      }
    },
    'updateVoucherCode/rejected': (
      state: VoucherState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, voucherCodeError: action.error.name }
    },
    'deleteVoucherCode/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'deleteVoucherCode/fulfilled': (state: VoucherState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        voucherCodes: state.voucherCodes.filter((code) => code._id !== action.payload._id),
        voucherCodeError: null,
      }
    },
    'deleteVoucherCode/rejected': (
      state: VoucherState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, voucherCodeError: action.error.name }
    },
    'getRedemptionLog/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getRedemptionLog/fulfilled': (state: VoucherState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        voucherRedemptionLog: action.payload,
      }
    },
    'getRedemptionLog/rejected': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'redeemVoucher/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'redeemVoucher/fulfilled': (state: VoucherState) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'redeemVoucher/rejected': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'getVoucherDefinitionByCode/pending': (state: VoucherState) => {
      return { ...state, apiStatus: ApiStatus.pending, voucherRedeemError: null }
    },
    'getVoucherDefinitionByCode/fulfilled': (state: VoucherState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        voucher: action.payload,
      }
    },
    'getVoucherDefinitionByCode/rejected': (
      state: VoucherState,
      action: {
        payload: unknown
        error: { name: string; message: string }
        type: string
      }
    ) => {
      return { ...state, apiStatus: ApiStatus.rejected, voucherRedeemError: action.error.message }
    },
  },
})

export const {
  getVouchers,
  createVoucher,
  updateVoucher,
  deleteVoucher,
  createVoucherCode,
  updateVoucherCode,
  deleteVoucherCode,
  redeemVoucher,
  getRedemptionLog,
  getVoucherDefinitionByCode,
} = asyncReducers

export const { initialVoucherState, setVoucher, resetVoucher, setVoucherCodes, setVoucherCode } =
  voucherSlice.actions

export default voucherSlice.reducer
