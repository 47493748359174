/**
 * Transform request options to string in format: key=value&key=value
 * @param params
 * @returns
 */
const transformRequestOptions = (params) => {
  const options = []
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value) && value.length) {
      value.forEach((el) => {
        options.push(`${key}=${el}`)
      })
    } else if (value) {
      options.push(`${key}=${value}`)
    }
  }
  return options.join('&')
}

export default transformRequestOptions
