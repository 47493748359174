import React, { FunctionComponent, lazy } from 'react'
import CrmPaths from '@rikaiportal/shared/modules/crm/CrmPaths'
import ProtectedRoute from '@rikaiportal/shared/components/ProtectedRoute'
import { UserRole } from '@rikaiportal/shared/types'
import { Switch } from 'react-router-dom'
import { delayedImport } from '@rikaiportal/shared/lib/delayed-import'
import { ROUTE_CONFIG } from '../route.config'

const CrmDashboard = lazy(() => delayedImport('crm/CrmDashboard', ROUTE_CONFIG))
const UserDetail = lazy(() => delayedImport('crm/CrmUserDetail', ROUTE_CONFIG))
const OrderDetail = lazy(() => delayedImport('crm/CrmOrderDetail', ROUTE_CONFIG))

const CrmRoutes: FunctionComponent = () => {
  return (
    <>
      <Switch>
        <ProtectedRoute
          exact
          path={CrmPaths.DASHBOARD}
          component={CrmDashboard}
          allow={[UserRole.CRM_ADMIN, UserRole.DOCTOR]}
        />
        <ProtectedRoute
          exact
          path={CrmPaths.USER_INDEX}
          component={UserDetail}
          allow={[UserRole.CRM_ADMIN, UserRole.DOCTOR]}
        />
        <ProtectedRoute
          exact
          path={CrmPaths.ORDER_INDEX}
          component={OrderDetail}
          allow={[UserRole.CRM_ADMIN, UserRole.DOCTOR]}
        />
      </Switch>
    </>
  )
}

export default CrmRoutes
