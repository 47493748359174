import { AxiosRequestConfig } from 'axios'
import { UnaryFunction } from '../types/unary-function.type'
import { LAST_REQUEST_LS_KEY } from '../authentication-client/src/lib/services/authentication.service'

export interface CreateIdleInterceptorParams {
  debounceTime: number
}

export function createIdleInterceptor({
  debounceTime,
}: CreateIdleInterceptorParams): UnaryFunction<AxiosRequestConfig, AxiosRequestConfig> {
  let timeoutId = null

  return (config: AxiosRequestConfig) => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(
      () => localStorage.setItem(LAST_REQUEST_LS_KEY, `${Date.now()}`),
      debounceTime
    )

    return config
  }
}
