import { compose } from '@reduxjs/toolkit'
import adminApi from '@rikaiportal/shared/api/admin'
import authenticationApi from '@rikaiportal/shared/api/auth'
import crmApi from '@rikaiportal/shared/api/crm'
import customerApi from '@rikaiportal/shared/api/customer'
import homeSamplingApi from '@rikaiportal/shared/api/homesampling'
import voucherApi from '@rikaiportal/shared/api/voucher'
import {
  authenticationClient,
  selectAuthTokenId,
} from '@rikaiportal/shared/authentication-client/src/lib'
import { createAuthorizationInterceptor } from '@rikaiportal/shared/interceptors/authorization.interceptor'

import { createLanguageInterceptor } from '@rikaiportal/shared/interceptors/language.interceptor'
import { identity } from '@rikaiportal/shared/lib/identity'

import i18next from 'i18next'
import { createIdleInterceptor } from '../interceptors/idle.interceptor'
import { createUnauthorizedInterceptor } from '../interceptors/unauthorized.interceptor'
import { AppStore } from '../modules/store'

export const WHITE_LISTED_URLS = [
  '/auth/verify',
  '/auth/login',
  '/auth/signup-verify',
  '/auth/refresh-token',
]

const REQUEST_DEFAULT_DEBOUNCE_TIME = 1000

export function setupInterceptors(
  store: AppStore,
  requestDebounceTime: number = REQUEST_DEFAULT_DEBOUNCE_TIME
): void {
  const languageInterceptor = createLanguageInterceptor(() => i18next.language)

  const errorInterceptor = createUnauthorizedInterceptor({
    whitelistedUrls: WHITE_LISTED_URLS,
    store,
    onUnauthorized: () => store.dispatch(authenticationClient.logout()),
  })

  const getToken = compose(selectAuthTokenId, store.getState)

  const authenticationInterceptor = createAuthorizationInterceptor(getToken)

  const idleInterceptor = createIdleInterceptor({
    debounceTime: requestDebounceTime,
  })

  const APIS = [authenticationApi, adminApi, customerApi, homeSamplingApi, crmApi, voucherApi]

  APIS.forEach((api) => {
    api.interceptors.request.use(authenticationInterceptor)
    api.interceptors.request.use(languageInterceptor)
    api.interceptors.request.use(idleInterceptor)
    api.interceptors.response.use(identity, errorInterceptor)
  })
}
