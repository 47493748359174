import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { BlockedDaysOutput } from '../../../admin/src/types'
import api from '../../api/admin'
import customerApi from '../../api/customer'
import {
  AdminState,
  ApiStatus,
  BlockedDays,
  Application,
  PartnerBranch,
  RuntimeSetting,
} from '../../types'

const initialState: AdminState = {
  adminAuth: null,
  adminOnlyMode: false,
  languageSwitching: false,
  disableStore: false,
  displayPrice: true,
  runtimeSettings: [],
  users: [],
  recentlyUpdatedRecommendations: false,
  recentlyUpdatedBadges: false,
  recentlyUpdatedResultCategories: false,
  recentlyUpdatedFooterCategories: false,
  recentlyUpdatedPages: false,
  recentlyUpdatedStoreMaintenance: false,
  recentlyUpdatedCriticalLimit: false,
  apiStatus: ApiStatus.idle,
  orders: [],
  errors: '',
  userPurged: false,
  userPurgedFromAuth0: false,
  appointments: [],
  recentlyUpdatedCMSDocuments: {
    resultCategories: false,
    doctorRecommendations: false,
    badges: false,
    footerLinks: false,
    storeMaintenance: false,
    pages: false,
    criticalLimit: false,
  },
  languageOptions: [],
  riskAcceptancePDF: { status: ApiStatus.idle, data: null },
  partnerBranches: [],
  allPartnerBranches: [],
  blockedDays: null,
  allBlockedDays: [],
  smsSent: { status: ApiStatus.idle, data: false },
  latestSmsSent: { status: ApiStatus.idle, data: null },
}

const asyncReducers = {
  getAdminOnlySetting: createAsyncThunk('admin/getAdminOnlySetting', async () => {
    const response = await api.get('/runtime/adminonly')

    return response.data
  }),
  getLanguageSwitchingSetting: createAsyncThunk('admin/getLanguageSwitchingSetting', async () => {
    const response = await api.get('/runtime/languageswitching')

    return response.data
  }),

  getDisableStoreSetting: createAsyncThunk('admin/getDisableStoreSetting', async () => {
    const response = await api.get('/runtime/disable-store')

    return response.data
  }),

  getDisplayPriceSetting: createAsyncThunk('admin/getDisplayPriceSetting', async () => {
    const response = await api.get(`/runtime/displayprice`)

    return response.data
  }),

  checkAdminAuth: createAsyncThunk('admin/checkAdminAuth', async () => {
    const response = await api.get('/runtime/checkadminauth')
    return response.data
  }),
  checkRecommendationUpdate: createAsyncThunk('admin/checkRecommendationUpdate', async () => {
    const response = await customerApi.get('/admin/updates/recommendations')
    return response.data
  }),
  updateRecommendations: createAsyncThunk('admin/updateRecommendations', async () => {
    const response = await customerApi.put('/admin/updates/recommendations', null)

    return response.data
  }),
  checkBadgeUpdate: createAsyncThunk('admin/checkBadgeUpdate', async () => {
    const response = await customerApi.get('/admin/updates/badges')
    return response.data
  }),
  updateBadges: createAsyncThunk('admin/updateBadges', async () => {
    const response = await customerApi.put('/admin/updates/badges', null)

    return response.data
  }),
  checkResultCategoriesUpdate: createAsyncThunk('admin/checkResultCategoriesUpdate', async () => {
    const response = await customerApi.get('/admin/updates/resultcategories')
    return response.data
  }),
  updateResultCategories: createAsyncThunk('admin/updateResultCategories', async () => {
    const response = await customerApi.put('/admin/updates/resultcategories', null)

    return response.data
  }),
  checkFooterCategoriesUpdate: createAsyncThunk('admin/checkFooterCategoriesUpdate', async () => {
    const response = await customerApi.get('/admin/updates/footercategories')
    return response.data
  }),
  updateFooterCategories: createAsyncThunk('admin/updateFooterCategories', async () => {
    const response = await customerApi.put('/admin/updates/footercategories', null)

    return response.data
  }),
  checkPagesUpdate: createAsyncThunk('admin/checkPagesUpdate', async () => {
    const response = await customerApi.get('/admin/updates/pages')
    return response.data
  }),
  updatePages: createAsyncThunk('admin/updatePages', async () => {
    const response = await customerApi.put('/admin/updates/pages', null)
    return response.data
  }),

  checkStoreMaintenanceUpdate: createAsyncThunk('admin/checkStoreMaintenanceUpdate', async () => {
    const response = await customerApi.get('/admin/updates/store-maintenance')
    return response.data
  }),
  updateStoreMaintenance: createAsyncThunk('admin/updateStoreMaintenance', async () => {
    const response = await customerApi.put('/admin/updates/store-maintenance', null)
    return response.data
  }),
  updateCriticalLimit: createAsyncThunk('admin/updateCriticalLimit', async () => {
    const response = await customerApi.put('/admin/updates/critical-limit', null)
    return response.data
  }),
  getRuntimeSettings: createAsyncThunk('admin/getRuntimeSettings', async () => {
    const response = await api.get('/runtime/settings')
    return response.data
  }),
  getUsersForAdmin: createAsyncThunk('admin/getUsersForAdmin', async () => {
    const response = await customerApi.get('/admin/users')
    return response.data
  }),
  getOrdersForAdmin: createAsyncThunk('admin/getOrdersForAdmin', async () => {
    const response = await customerApi.get('/admin/orders')
    return response.data
  }),
  getAppointmentsForAdmin: createAsyncThunk('admin/getAppointmentsForAdmin', async () => {
    const response = await customerApi.get('/admin/appointments')
    return response.data
  }),
  saveRuntimeSetting: createAsyncThunk(
    'admin/saveRuntimeSetting',
    async (params: {
      name: string
      value: string | boolean | number | Array<{ name: string; value: boolean }>
    }) => {
      const response = await api.post(`/runtime/${params.name}`, { value: params.value })

      return response.data
    }
  ),
  purgeUserData: createAsyncThunk('admin/purgeUserData', async (params: { userId: string }) => {
    const response = await customerApi.delete(`/admin/users/${params.userId}`)

    return response.data
  }),
  purgeUserFromAuth0: createAsyncThunk(
    'admin/purgeUserFromAuth0',
    async (params: { userId: string }) => {
      const response = await api.delete(`/runtime/auth0/users/${params.userId}`)

      return response.data
    }
  ),
  checkForCmsUpdates: createAsyncThunk('admin/checkForCmsUpdates', async () => {
    const response = await customerApi.get('/admin/updates/all')
    return response.data
  }),
  riskAcceptancePDFDownload: createAsyncThunk(
    'admin/riskAcceptancePDFDownload',
    async (orderNo: string) => {
      const response = await api.post('/admin/risk-acceptance-download', { orderNo })
      return response.data
    }
  ),
  getPartnerBranchesForAdmin: createAsyncThunk('admin/getPartnerBranchesForAdmin', async () => {
    const response = await customerApi.get('/partner-branches/admin')
    return response.data
  }),
  fetchAllPartnerBranchesForAdmin: createAsyncThunk(
    'admin/fetchAllPartnerBranchesForAdmin',
    async (params: { applications: Application[] }) => {
      const { applications } = params
      const response = await customerApi.get('/partner-branches/admin/list', {
        params: { applications },
      })
      return response.data
    }
  ),
  fetchAllPartnerBlockedDays: createAsyncThunk('admin/fetchAllPartnerBlockedDays', async () => {
    const response = await api.get('/partner-branches/admin/blocked-days/list')
    return response.data
  }),
  saveBlockedDays: createAsyncThunk(
    'admin/saveBlockedDays',
    async (blockedDays: BlockedDaysOutput) => {
      const response = await api.put('/partner-branches/admin/blocked-days', blockedDays)
      return response.data
    }
  ),
  deleteBlockedDays: createAsyncThunk('admin/deleteBlockedDays', async (blockedDaysId: string) => {
    const response = await api.delete(`partner-branches/admin/blocked-days/${blockedDaysId}`)
    return response.data
  }),
  sendSmsToUser: createAsyncThunk(
    'admin/sendSmsToUser',
    async (params: { phoneNo: string; message: string; userId: string }) => {
      try {
        const response = await customerApi.post(`/admin/send-sms`, {
          phoneNo: params.phoneNo,
          message: params.message,
          userId: params.userId,
        })
        return response.data
      } catch (error) {
        throw error.response.data
      }
    }
  ),
  getLatestSMS: createAsyncThunk('admin/getLatestSMS', async (params: { userId: string }) => {
    const response = await customerApi.get(`/admin/latest-sms`, {
      params: { userId: params.userId },
    })
    return response.data
  }),
}

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    initialAdminState: () => {
      return initialState
    },
    setAppAuth: (state: AdminState, action) => {
      return { ...state, appAuth: action.payload }
    },
    setAdminAuth: (state: AdminState, action) => {
      return { ...state, adminAuth: action.payload }
    },
    resetPurgeStatus: (state: AdminState) => {
      return { ...state, userPurged: false, userPurgedFromAuth0: false }
    },
    resetError: (state: AdminState) => {
      return { ...state, errors: '', apiStatus: ApiStatus.idle }
    },
    setRiskAcceptancePDF: (state: AdminState, action) => {
      return {
        ...state,
        riskAcceptancePDF: {
          data: action.payload,
          status: ApiStatus.idle,
        },
      }
    },
    addNewPartner: (state: AdminState, action: PayloadAction<PartnerBranch>) => {
      return { ...state, allPartnerBranches: [...state.allPartnerBranches, action.payload] }
    },
    setAllPartnerBranch: (state, action: PayloadAction<PartnerBranch[]>) => {
      return { ...state, allPartnerBranches: action.payload }
    },
    setBlockedDays: (state: AdminState, action: PayloadAction<BlockedDays>) => {
      return { ...state, blockedDays: action.payload }
    },
    setRuntimeSetting: (state: AdminState, action: PayloadAction<RuntimeSetting[]>) => {
      return {
        ...state,
        runtimeSettings: action.payload,
        languageSwitching: action.payload.find((res) => res.name === 'LANGUAGE_SWITCHING_ENABLE')
          ?.value as boolean,
        languageOptions: action.payload.find((res) => res.name === 'LANGUAGE_SWITCHING_OPTIONS')
          ?.value as { name: string; value: boolean }[],
      }
    },
    resetSentSmsStatus: (state: AdminState) => {
      return {
        ...state,
        smsSent: {
          status: ApiStatus.idle,
          data: null,
          errorMessage: null,
          error: null,
        },
      }
    },
    'checkAdminAuth/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkAdminAuth/fulfilled': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.fulfilled }
    },
    'checkAdminAuth/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'checkRecommendationUpdate/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkRecommendationUpdate/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedRecommendations: !!action.payload,
        recentlyUpdatedCMSDocuments: {
          ...state.recentlyUpdatedCMSDocuments,
          doctorRecommendations: !!action.payload,
        },
      }
    },
    'checkRecommendationUpdate/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'checkForCmsUpdates/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkForCmsUpdates/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedCMSDocuments: action.payload,
      }
    },
    'checkForCmsUpdates/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'updateRecommendations/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateRecommendations/fulfilled': (state: AdminState) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'updateRecommendations/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'checkBadgeUpdate/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkBadgeUpdate/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedBadges: !!action.payload,
        recentlyUpdatedCMSDocuments: {
          ...state.recentlyUpdatedCMSDocuments,
          badges: !!action.payload,
        },
      }
    },
    'checkBadgeUpdate/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'updateBadges/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateBadges/fulfilled': (state: AdminState) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'updateBadges/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'checkResultCategoriesUpdate/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkResultCategoriesUpdate/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedResultCategories: !!action.payload,
        recentlyUpdatedCMSDocuments: {
          ...state.recentlyUpdatedCMSDocuments,
          resultCategories: !!action.payload,
        },
      }
    },
    'checkResultCategoriesUpdate/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'updateResultCategories/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateResultCategories/fulfilled': (state: AdminState) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'updateResultCategories/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'checkFooterCategoriesUpdate/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkFooterCategoriesUpdate/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedFooterCategories: !!action.payload,
      }
    },
    'checkFooterCategoriesUpdate/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'updateFooterCategories/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateFooterCategories/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedCMSDocuments: {
          ...state.recentlyUpdatedCMSDocuments,
          footerLinks: !!action.payload,
        },
      }
    },
    'updateFooterCategories/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'checkPagesUpdate/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkPagesUpdate/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedPages: !!action.payload,
        recentlyUpdatedCMSDocuments: {
          ...state.recentlyUpdatedCMSDocuments,
          pages: !!action.payload,
        },
      }
    },
    'checkPagesUpdate/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'updatePages/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updatePages/fulfilled': (state: AdminState) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'updatePages/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'checkStoreMaintenanceUpdate/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkStoreMaintenanceUpdate/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedStoreMaintenance: !!action.payload,
        recentlyUpdatedCMSDocuments: {
          ...state.recentlyUpdatedCMSDocuments,
          storeMaintenance: !!action.payload,
        },
      }
    },
    'checkStoreMaintenanceUpdate/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'updateStoreMaintenance/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateStoreMaintenance/fulfilled': (state: AdminState) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'updateStoreMaintenance/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'checkCriticalLimitUpdate/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'checkCriticalLimitUpdate/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        recentlyUpdatedCriticalLimit: !!action.payload,
        recentlyUpdatedCMSDocuments: {
          ...state.recentlyUpdatedCMSDocuments,
          criticalLimit: !!action.payload,
        },
      }
    },
    'checkCriticalLimitUpdate/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'updateCriticalLimit/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'updateCriticalLimit/fulfilled': (state: AdminState) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'updateCriticalLimit/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'getAdminOnlySetting/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getAdminOnlySetting/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        adminOnlyMode: action.payload,
      }
    },
    'getLanguageSwitchingSetting/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getLanguageSwitchingSetting/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        languageSwitching: !!action.payload.value,
        languageOptions: action.payload.languages,
      }
    },
    'getDisableStoreSetting/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getDisableStoreSetting/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        disableStore: action.payload,
      }
    },
    'getDisplayPriceSetting/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getDisplayPriceSetting/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        displayPrice: action.payload,
      }
    },
    'getRuntimeSettings/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getRuntimeSettings/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        runtimeSettings: action.payload,
        languageSwitching: action.payload.find((res) => res.name === 'LANGUAGE_SWITCHING_ENABLE')
          ?.value,
      }
    },
    'getRuntimeSettings/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'getUsersForAdmin/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getUsersForAdmin/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        users: action.payload,
      }
    },
    'getUsersForAdmin/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'getOrdersForAdmin/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getOrdersForAdmin/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        orders: action.payload,
      }
    },
    'getAppointmentsForAdmin/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getAppointmentsForAdmin/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        appointments: action.payload,
      }
    },
    'getOrdersForAdmin/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'saveRuntimeSetting/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'saveRuntimeSetting/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        runtimeSettings: action.payload,
        languageSwitching: action.payload.find((res) => res.name === 'LANGUAGE_SWITCHING_ENABLE')
          ?.value,
        languageOptions: action.payload.find((res) => res.name === 'LANGUAGE_SWITCHING_OPTIONS')
          ?.value,
      }
    },
    'saveRuntimeSetting/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, adminAuth: null }
    },
    'purgeUserData/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'purgeUserData/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        userPurged: action.payload,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'purgeUserData/rejected': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.rejected,
        adminAuth: null,
        errors: action.payload,
      }
    },
    'purgeUserFromAuth0/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'purgeUserFromAuth0/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        userPurgedFromAuth0: action.payload,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'purgeUserFromAuth0/rejected': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.rejected,
        adminAuth: null,
        errors: action.payload,
      }
    },
    'riskAcceptancePDFDownload/pending': (state: AdminState) => {
      return {
        ...state,
        riskAcceptancePDF: {
          data: null,
          status: ApiStatus.pending,
        },
      }
    },
    'riskAcceptancePDFDownload/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        riskAcceptancePDF: {
          data: action.payload,
          status: ApiStatus.fulfilled,
        },
      }
    },
    'riskAcceptancePDFDownload/rejected': (state: AdminState) => {
      return {
        ...state,
        riskAcceptancePDF: {
          data: null,
          status: ApiStatus.rejected,
        },
        errors: 'File not Found',
      }
    },
    'getPartnerBranchesForAdmin/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'getPartnerBranchesForAdmin/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        partnerBranches: action.payload,
        apiStatus: ApiStatus.fulfilled,
      }
    },
    'getPartnerBranchesForAdmin/rejected': (state: AdminState) => {
      return {
        ...state,
        apiStatus: ApiStatus.rejected,
        partnerBranches: [],
      }
    },
    'fetchAllPartnerBranchesForAdmin/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'fetchAllPartnerBranchesForAdmin/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        allPartnerBranches: action.payload,
      }
    },
    'fetchAllPartnerBranchesForAdmin/rejected': (state: AdminState) => {
      return {
        ...state,
        apiStatus: ApiStatus.rejected,
        allPartnerBranches: [],
      }
    },
    'fetchAllPartnerBlockedDays/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'fetchAllPartnerBlockedDays/fulfilled': (state: AdminState, action) => {
      return { ...state, apiStatus: ApiStatus.fulfilled, allBlockedDays: action.payload }
    },
    'fetchAllPartnerBlockedDays/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected, allBlockedDays: [] }
    },
    'saveBlockedDays/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'saveBlockedDays/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        blockedDays: action.payload,
        allBlockedDays: [...state.allBlockedDays, action.payload],
      }
    },
    'saveBlockedDays/rejected': (state: AdminState) => {
      return { ...state, blockedDays: null, apiStatus: ApiStatus.rejected }
    },
    'deleteBlockedDays/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'deleteBlockedDays/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        blockedDays: action.payload,
        allBlockedDays: state.allBlockedDays.filter((item) => item._id !== action.payload._id),
      }
    },
    'deleteBlockedDays/rejected': (state: AdminState) => {
      return { ...state, blockedDays: null, apiStatus: ApiStatus.rejected }
    },
    'uploadPartnerLogo/pending': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.pending }
    },
    'uploadPartnerLogo/fulfilled': (state: AdminState, action: PayloadAction<PartnerBranch>) => {
      return {
        ...state,
        apiStatus: ApiStatus.fulfilled,
        allPartnerBranches: state.allPartnerBranches.map((partner) => {
          if (partner._id === action.payload._id) {
            return action.payload
          }
          return partner
        }),
      }
    },
    'uploadPartnerLogo/rejected': (state: AdminState) => {
      return { ...state, apiStatus: ApiStatus.rejected }
    },
    'sendSmsToUser/pending': (state: AdminState) => {
      return { ...state, smsSent: { status: ApiStatus.pending, data: null } }
    },
    'sendSmsToUser/fulfilled': (state: AdminState) => {
      return {
        ...state,
        smsSent: { status: ApiStatus.fulfilled, data: true },
      }
    },
    'sendSmsToUser/rejected': (
      state: AdminState,
      action: PayloadAction<
        any,
        string,
        never,
        {
          message: string
        }
      >
    ) => {
      let errorMessage = action.error.message
      if (action.error.message === 'TOO_FREQUENTLY') {
        errorMessage = i18next.t(
          'You’re sending a message too quickly after last sent. Please wait for 15 minutes from last sent before you try again.'
        )
      }
      return {
        ...state,
        smsSent: {
          status: ApiStatus.rejected,
          data: false,
          errorMessage,
        },
      }
    },
    'getLatestSMS/pending': (state: AdminState) => {
      return { ...state, latestSmsSent: { status: ApiStatus.pending, data: null } }
    },
    'getLatestSMS/fulfilled': (state: AdminState, action) => {
      return {
        ...state,
        latestSmsSent: { status: ApiStatus.fulfilled, data: action.payload },
      }
    },
    'getLatestSMS/rejected': (state: AdminState) => {
      return { ...state, latestSmsSent: { status: ApiStatus.rejected, data: null } }
    },
  },
})

export const {
  getAdminOnlySetting,
  getRuntimeSettings,
  getUsersForAdmin,
  getOrdersForAdmin,
  saveRuntimeSetting,
  checkAdminAuth,
  purgeUserData,
  checkRecommendationUpdate,
  updateRecommendations,
  checkBadgeUpdate,
  updateBadges,
  checkResultCategoriesUpdate,
  updateResultCategories,
  checkFooterCategoriesUpdate,
  updateFooterCategories,
  updatePages,
  checkPagesUpdate,
  getLanguageSwitchingSetting,
  getDisplayPriceSetting,
  purgeUserFromAuth0,
  getAppointmentsForAdmin,
  getDisableStoreSetting,
  checkStoreMaintenanceUpdate,
  updateStoreMaintenance,
  updateCriticalLimit,
  checkForCmsUpdates,
  riskAcceptancePDFDownload,
  getPartnerBranchesForAdmin,
  fetchAllPartnerBranchesForAdmin,
  fetchAllPartnerBlockedDays,
  saveBlockedDays,
  deleteBlockedDays,
  sendSmsToUser,
  getLatestSMS,
} = asyncReducers

export const {
  initialAdminState,
  setAppAuth,
  setAdminAuth,
  resetPurgeStatus,
  resetError,
  setRiskAcceptancePDF,
  addNewPartner,
  setAllPartnerBranch,
  setBlockedDays,
  setRuntimeSetting,
  resetSentSmsStatus,
} = adminSlice.actions

export default adminSlice.reducer
