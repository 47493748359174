export enum AuthPaths {
  AUTH_LOGIN = '/auth/login',
  AUTH_SIGNUP = '/auth/signup',
  AUTH_VERIFY = '/auth/verify',
  AUTH_SIGNUP_VERIFY = '/auth/signup-verify',
  USER_INFO = '/auth/signup-user-info',
  USER_ADDRESS = '/auth/signup-user-address',
}

export default AuthPaths
