import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '../../api/customer'
import transformRequestOptions from '../../lib/transformRequestOptions'
import {
  ApiStatus,
  Application,
  EventFetchingData,
  GroupOfTimeSlots,
  Channel,
  Product,
  ProductState,
} from '../../types'

const initialState: ProductState = {
  productGroups: [],
  selectedProductGroup: null,
  selectedProduct: null,
  selectedProductGroupAddOns: [],
  partnerBranchSearchText: '',
  partnerBranchSearchLocation: null,
  productPartnerBranches: [],
  productGroupAddOns: null,
  selectedPartnerBranch: null,
  partnerBranchTimeSlots: [],
  partnerBranchTimeSlotsListInRange: { statusLoadingTimeSlot: '', multiDaystimeSlots: [] },
  partnerBranchMultiDaysTimeSlots: [],
  selectedDate: null,
  selectedTimeSlot: null,
  productOrder: null,
  apiStatus: ApiStatus.idle,
  error: '',
  partnerBranch: null,
  customTimeSlot: null,
}

const asyncReducers = {
  fetchAllProductGroups: createAsyncThunk(
    'products/getAllProductGroups',
    async (application?: 'all' | Application.RIKAI | Application.HOMESAMPLING) => {
      const response = await api.get(`/product-groups/${application}`)
      return response.data
    }
  ),
  fetchProductGroupAddOns: createAsyncThunk(
    'products/getProductGroupAddOns',
    async (id: string) => {
      const response = await api.get(`/product-groups/${id}/addons`)
      return response.data
    }
  ),
  fetchProductPartnerBranch: createAsyncThunk(
    'products/getProductPartnerBranches',
    async (params: {
      productId: string
      searchText?: string
      lat?: number
      lng?: number
      includeClosed?: boolean
      channel?: Channel[]
    }) => {
      const { productId, searchText, lat, lng, includeClosed, channel } = params

      if (!(lat && lng) && searchText?.length < 3) {
        return []
      }

      const response = await api.get('/partner-branches', {
        params: {
          productId,
          query: searchText,
          lat,
          lng,
          includeClosed,
          channel: channel ?? [Channel.CUSTOMER],
        },
        paramsSerializer: (params) => transformRequestOptions(params),
      })
      return response.data
    }
  ),
  fetchPartnerBranchTimeSlots: createAsyncThunk(
    'products/getPartnerBranchTimeSlots',
    async (params: { partnerBranchId: string; selectedDate: string }) => {
      const { partnerBranchId, selectedDate } = params
      const response = await api.get(`/partner-branch/${partnerBranchId}/timeslots`, {
        params: {
          selectedDate,
        },
      })

      return response.data
    }
  ),
  fetchPartnerBranch: createAsyncThunk(
    'products/getPartnerBranch',
    async (params: { key: string }) => {
      const { key } = params
      const response = await api.get(`/partner-branch/partner-sales/${key}`)
      return response.data
    }
  ),
  fetchPartnerBranchMultiDaysTimeSlots: createAsyncThunk(
    'products/getPartnerBranchMultiDaysTimeSlots',
    async (params: { partnerBranchId: string; selectedDate: string; days: number }) => {
      const { partnerBranchId, selectedDate, days } = params
      const response = await api.get(`/partner-branch/${partnerBranchId}/multidaystimeslots`, {
        params: {
          selectedDate,
          days,
        },
      })

      return response.data
    }
  ),
  fetchPartnerBranchMultipleAvailableDaysTimeSlots: createAsyncThunk(
    'products/getPartnerBranchMultipleAvailableDaysTimeSlots',
    async (params: {
      partnerBranchId: string
      startDate?: string
      limit: number
      event: EventFetchingData
    }) => {
      const { partnerBranchId, startDate, limit, event } = params
      const response = await api.get(
        `/partner-branch/${partnerBranchId}/multidays_available_timeslots`,
        {
          params: {
            startDate,
            limit,
          },
        }
      )

      return { event, data: response.data }
    }
  ),
  saveProductOrder: createAsyncThunk(
    'products/saveProductOrder',
    async (params: {
      customerId: string
      productId: string
      productPrice: number
      partnerBranchId: string
      scheduleFrom: Date | string
      scheduleTo: Date | string
      addOnIds: string[]
      voucherCode?: string
      isSpecialDataSharingOrder?: boolean
      channel?: Channel
      utcOffset?: number
      bloodDrawMethod?: string
    }) => {
      try {
        const data = { ...params }
        if (!data.voucherCode) delete data.voucherCode
        const response = await api.post(`/orders`, data)
        return response.data
      } catch (err) {
        const customError = {
          name: 'Custom saveProductOrder error',
          message: err.response.data.message,
          data: err.response.data,
        }
        throw customError
      }
    }
  ),
}

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    initialProductsState: () => {
      return initialState
    },
    selectProductGroup: (productState: ProductState, action) => {
      const selectedProductGroup =
        productState.productGroups.find((productGroup) => {
          return productGroup.key === action.payload && productGroup.active === true
        }) || null

      return { ...productState, selectedProductGroup }
    },
    setIndividualProductGroup: (productState: ProductState, action) => {
      return { ...productState, selectedProductGroup: action.payload }
    },
    selectProductGroupAddOns: (productState: ProductState, action) => {
      return { ...productState, selectedProductGroupAddOns: action.payload }
    },
    deselectProductGroupAddOn: (productState: ProductState, action) => {
      return { ...productState, selectedProductGroupAddOns: action.payload }
    },
    selectProduct: (productState: ProductState, action) => {
      const selectedProduct =
        productState.selectedProductGroup?.products.find(
          (product) => product.key === action.payload && product.active === true
        ) || null
      return { ...productState, selectedProduct, partnerBranchSearchText: '' }
    },
    selectFollowUpProduct: (productState: ProductState, action: PayloadAction<Product>) => {
      return { ...productState, selectedProduct: action.payload }
    },
    selectPartnerBranch: (productState: ProductState, action) => {
      let selectedPartnerBranch
      if (!productState.partnerBranch) {
        selectedPartnerBranch =
          productState.productPartnerBranches?.find(
            (branch) => branch.key === action.payload && branch.active === true
          ) || null
      } else {
        selectedPartnerBranch = productState.partnerBranch
      }
      return { ...productState, selectedPartnerBranch }
    },
    selectTimeSlot: (productState: ProductState, action) => {
      const selectedTimeSlot =
        productState.partnerBranchTimeSlots?.find(
          (timeSlot) => timeSlot.from === action.payload?.from && timeSlot.to === action.payload?.to
        ) || null

      return { ...productState, selectedTimeSlot }
    },
    setCustomTimeSlot: (productState: ProductState, action) => {
      const customTimeSlot = action.payload
      return { ...productState, customTimeSlot }
    },
    selectDate: (productState: ProductState, action) => {
      return { ...productState, selectedDate: action.payload }
    },
    selectMultipleTimeSlot: (productState: ProductState, action) => {
      return { ...productState, selectMultiTimeSlots: action.payload }
    },
    setSelectTimeSlotFromMultipleTimeSlotsData: (productState: ProductState, action) => {
      const result = productState.partnerBranchTimeSlotsListInRange.multiDaystimeSlots.map(
        (data) => {
          const timeslotItem = data.timeSlots.find(
            (timeSlot) => timeSlot.from === action.payload.from && timeSlot.to === action.payload.to
          )
          return timeslotItem ? action.payload : null
        }
      )

      const selectedTimeSlot = result.find((ele) => ele !== null)
      return { ...productState, selectedTimeSlot }
    },
    setPartnerBranchTimeslot: (productState: ProductState, action) => {
      return { ...productState, partnerBranchTimeSlots: action.payload }
    },
    setPartnerBranchSearchText: (productState: ProductState, action) => {
      return { ...productState, partnerBranchSearchText: action.payload }
    },
    setPartnerBranchSearchTextandLocation: (productState: ProductState, action) => {
      return {
        ...productState,
        partnerBranchSearchText: action.payload.text,
        partnerBranchSearchLocation: { lat: action.payload.lat, lng: action.payload.lng },
      }
    },
    setPartnerBranches: (productState: ProductState, action) => {
      return { ...productState, productPartnerBranches: action.payload }
    },
    setProductOrder: (productState: ProductState, action) => {
      return { ...productState, productOrder: action.payload }
    },
    setProductError: (productState: ProductState, action: PayloadAction<string>) => {
      return { ...productState, error: action.payload }
    },
    resetError: (productState: ProductState) => {
      return { ...productState, error: '' }
    },
    resetApiStatus: (productState: ProductState) => {
      return {
        ...productState,
        apiStatus: ApiStatus.idle,
      }
    },
    'getAllProductGroups/pending': (productState: ProductState) => {
      return { ...productState, apiStatus: ApiStatus.pending }
    },
    'getAllProductGroups/fulfilled': (productState: ProductState, action) => {
      return {
        ...productState,
        apiStatus: ApiStatus.fulfilled,
        productGroups: action.payload,
      }
    },
    'getProductGroupAddOns/pending': (productState: ProductState) => {
      return { ...productState, apiStatus: ApiStatus.pending }
    },
    'getProductGroupAddOns/fulfilled': (productState: ProductState, action) => {
      return {
        ...productState,
        apiStatus: ApiStatus.fulfilled,
        productGroupAddOns: action.payload,
      }
    },
    'getProductPartnerBranches/pending': (productState: ProductState) => {
      return { ...productState, apiStatus: ApiStatus.pending }
    },
    'getProductPartnerBranches/fulfilled': (productState: ProductState, action) => {
      return {
        ...productState,
        apiStatus: ApiStatus.fulfilled,
        productPartnerBranches: action.payload,
      }
    },
    'getPartnerBranchTimeSlots/pending': (productState: ProductState) => {
      return { ...productState, apiStatus: ApiStatus.pending }
    },
    'getPartnerBranchTimeSlots/fulfilled': (productState: ProductState, action) => {
      return {
        ...productState,
        apiStatus: ApiStatus.fulfilled,
        partnerBranchTimeSlots: action.payload,
        selectedTimeSlot: null,
      }
    },
    'getPartnerBranchMultiDaysTimeSlots/pending': (productState: ProductState) => {
      return { ...productState, apiStatus: ApiStatus.pending }
    },
    'getPartnerBranchMultiDaysTimeSlots/fulfilled': (productState: ProductState, action) => {
      return {
        ...productState,
        apiStatus: ApiStatus.fulfilled,
        partnerBranchMultiDaysTimeSlots: action.payload,
        selectedDate: action.payload[0].date,
        selectedTimeSlot: null,
      }
    },
    'getPartnerBranch/fulfilled': (productState: ProductState, action) => {
      return {
        ...productState,
        apiStatus: ApiStatus.fulfilled,
        partnerBranch: action.payload,
      }
    },
    'getPartnerBranch/pending': (productState: ProductState) => {
      return { ...productState, apiStatus: ApiStatus.pending }
    },
    'getPartnerBranch/rejected': (productState: ProductState) => {
      return {
        ...productState,
        apiStatus: ApiStatus.rejected,
      }
    },
    'saveProductOrder/pending': (productState: ProductState) => {
      return { ...productState, apiStatus: ApiStatus.pending }
    },
    'saveProductOrder/fulfilled': (productState: ProductState, action) => {
      return { ...productState, apiStatus: ApiStatus.fulfilled, productOrder: action.payload }
    },
    'saveProductOrder/rejected': (
      productState: ProductState,
      action: { type: string; payload: unknown; error: { name: string; message: string } }
    ) => {
      return {
        ...productState,
        apiStatus: ApiStatus.rejected,
        error: action.error.message,
      }
    },
    'getPartnerBranchMultipleAvailableDaysTimeSlots/pending': (productState: ProductState) => {
      return { ...productState, apiStatus: ApiStatus.pending }
    },
    'getPartnerBranchMultipleAvailableDaysTimeSlots/fulfilled': (
      productState: ProductState,
      action
    ) => {
      // const result =
      //   action.payload.event === EventFetchingData.ADD_DATA
      //     ? [...productState.partnerBranchTimeSlotsListInRange, ...action.payload.data]
      //     : action.payload.data
      const result: GroupOfTimeSlots = {
        statusLoadingTimeSlot: action.payload.data.statusLoadingTimeSlot,
        multiDaystimeSlots:
          action.payload.event === EventFetchingData.ADD_DATA
            ? [
                ...productState.partnerBranchTimeSlotsListInRange.multiDaystimeSlots,
                ...action.payload.data.multiDaystimeSlots,
              ]
            : action.payload.data.multiDaystimeSlots,
      }
      return {
        ...productState,
        apiStatus: ApiStatus.fulfilled,
        partnerBranchTimeSlotsListInRange: result,
      }
    },
  },
})

export const {
  fetchAllProductGroups,
  fetchProductGroupAddOns,
  fetchProductPartnerBranch,
  fetchPartnerBranchTimeSlots,
  fetchPartnerBranchMultiDaysTimeSlots,
  fetchPartnerBranchMultipleAvailableDaysTimeSlots,
  fetchPartnerBranch,
  saveProductOrder,
} = asyncReducers

export const {
  initialProductsState,
  selectProduct,
  selectFollowUpProduct,
  selectProductGroup,
  selectPartnerBranch,
  selectProductGroupAddOns,
  deselectProductGroupAddOn,
  selectTimeSlot,
  setCustomTimeSlot,
  selectDate,
  setSelectTimeSlotFromMultipleTimeSlotsData,
  setPartnerBranchSearchText,
  setPartnerBranchSearchTextandLocation,
  setPartnerBranchTimeslot,
  setProductOrder,
  setPartnerBranches,
  setIndividualProductGroup,
  resetError,
  resetApiStatus,
  setProductError,
} = productSlice.actions

export default productSlice.reducer
