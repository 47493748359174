import { ResultRecommendation, TestResult } from '../types'

export const mapOlderRecommendationLinks = (
  recommendations: ResultRecommendation[]
): ResultRecommendation[] => {
  return recommendations.map((recommendation) => {
    if (!recommendation.link) {
      return recommendation
    }
    const { text, url } = recommendation.link
    if (typeof text === 'string' && typeof url === 'string') {
      recommendation.link.text = {
        en: text,
        de: text,
        fr: text,
        it: text,
      }
      recommendation.link.url = {
        en: url,
        de: url,
        fr: url,
        it: url,
      }
    } else {
      recommendation.link.text = {
        en: text?.en,
        de: text?.de,
        fr: text?.fr,
        it: text?.it,
      }
      recommendation.link.url = {
        en: url?.en,
        de: url?.de,
        fr: url?.fr,
        it: url?.it,
      }
    }

    return recommendation
  })
}

export const mapOlderTestRecommendationLinks = (testResult: TestResult): TestResult => {
  if (testResult.recommendations) {
    testResult.recommendations = mapOlderRecommendationLinks(testResult.recommendations)
  }
  return testResult
}
