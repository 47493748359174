import React, { FC } from 'react'
import { useDelay } from '../../react-utils/hooks/use-delay'
import './DelayedLoader.css'

export interface DelayLoaderProps {
  delay?: number
}

export const DelayedLoader: FC<DelayLoaderProps> = ({ delay = 0 }) => {
  const show = useDelay(delay)

  return (
    show && (
      <div className="line-loader">
        <div className="loader-element" />
      </div>
    )
  )
}
